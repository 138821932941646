import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { ConfigureCategory } from '@/types/OnecZup';

const baseUri = '/business_integrations';

export const setDadata = (data: object) =>
    request({
        url: `${baseUri}/dadata`,
        method: 'PUT',
        data,
    });

export const getDadataKey = (params: object) =>
    request({
        url: `${baseUri}/dadata/key`,
        method: 'GET',
        params,
    });

export const setFinolog = (data: object) =>
    request({
        url: `${baseUri}/finolog`,
        method: 'PUT',
        data,
    });

export const setWebhooks = (data: object) =>
    request({
        url: `${baseUri}/webhooks`,
        method: 'PUT',
        data,
    });

export const deleteById = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
        data,
    });

export const createOneC = (data: object) =>
    request({
        url: `${baseUri}/onec_accounting`,
        method: 'POST',
        data,
    });

export const updateOneC = (id: string, data: object) =>
    request({
        url: `${baseUri}/onec_accounting/${id}`,
        method: 'PATCH',
        data,
    });

export const getIntegrations = (params: object) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const createOneCZup = (data: object) =>
    request({
        url: `${baseUri}/onec_zup`,
        method: 'POST',
        data,
    });

export const getOneCEmployees = (params: object) =>
    request({
        url: `${baseUri}/onec_zup/onec_employees`,
        method: 'GET',
        params,
    });

export const getOneCEmployee = (id: string) =>
    request({
        url: `${baseUri}/onec_zup/onec_employees/${id}`,
        method: 'GET',
    });

export const updateOneCEmployee = (id: string, data: object) =>
    request({
        url: `${baseUri}/onec_zup/onec_employees/${id}/business_data`,
        method: 'PUT',
        data,
    });

export const getOneCOrganizations = (params: object) =>
    request({
        url: `${baseUri}/onec_zup/organizations`,
        method: 'GET',
        params,
    });

export const getOneCOrganization = (id: string) =>
    request({
        url: `${baseUri}/onec_zup/organizations/${id}`,
        method: 'GET',
    });

export const updateOneCZup = (id: string, data: object) =>
    request({
        url: `${baseUri}/onec_zup/${id}`,
        method: 'PATCH',
        data,
    });

export const getDocuments = (params: object) =>
    request({
        url: `${baseUri}/onec_zup/zup_documents`,
        method: 'GET',
        params,
    });

export const getDocument = (id: string, params: object) =>
    request({
        url: `${baseUri}/onec_zup/zup_documents/${id}`,
        method: 'GET',
        params,
    });

export const getSalaryPayTypes = (params: object) =>
    request({
        url: `${baseUri}/onec_zup/salary_pay_types`,
        method: 'GET',
        params,
    });

export const getSalaryPayType = (id: string, params: object) =>
    request({
        url: `${baseUri}/onec_zup/salary_pay_types/${id}`,
        method: 'GET',
        params,
    });

export const addQueueToSync = (id: string) =>
    request({
        url: `${baseUri}/onec_zup/zup_documents/${id}/queue_to_sync`,
        method: 'POST',
    });

export const getAccounts = (params: object) =>
    request({
        url: `${baseUri}/accounts`,
        method: 'GET',
        params,
    });

export const getZupConfigureCategories = (params: object): Promise<PagedCollection<ConfigureCategory>> =>
    request({
        url: `${baseUri}/onec_zup/configure_categories`,
        method: 'GET',
        params
    });

export const configureZupCategories = (id: string, data: object) =>
    request({
        url: `${baseUri}/onec_zup/onec_employees/${id}/configure`,
        method: 'PUT',
        data
    });

export const syncZupDocuments = (data: { business: string }) =>
    request({
        url: `${baseUri}/onec_zup/zup_documents/synchronize`,
        method: 'POST',
        data
    });
