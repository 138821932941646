<template>
    <el-alert
        :type="type"
        :closable="false"
        :show-icon="showIcon"
        class="u-mb-sm"
    >
        <div class="u-text-md">
            <slot />
        </div>
    </el-alert>
</template>

<script>
export default {
    name: 'UiAlert',
    props: {
        type: {
            type: String,
            default: 'warning',
        },
        showIcon: {
            type: Boolean,
            default: true
        }
    },
};
</script>
