<template>
    <ui-dialog
        :visible.sync="currentVisible"
        class="site-notification-dialog"
        width="1000px"
    >
        <template #title>
            <div class="site-notification-dialog__header-wrapper">
                <div class="site-notification-dialog__side">
                    <div class="site-notification-dialog__header">
                        <div class="site-notification-dialog__header-buttons">
                            <el-button
                                :loading="pending"
                                title="Отметить все прочитанными"
                                type="flat"
                                @click="handleReadAllClick"
                            >
                                <iconify-icon icon="mynaui:message-check-solid" />
                            </el-button>
                        </div>
                    </div>
                </div>
                <el-divider
                    class="u-h-auto u-m-none"
                    direction="vertical"
                />
                <div class="site-notification-dialog__main">
                    <div class="site-notification-dialog__chat-title">
                        {{ activeGroupTitle }}
                    </div>
                </div>
            </div>
            <el-divider class="u-m-none" />
        </template>

        <div class="site-notification-dialog__wrapper">
            <div class="site-notification-dialog__side">
                <div class="u-scrollable u-scrollable--y">
                    <el-skeleton
                        :count="7"
                        :loading="loading"
                        animated
                    >
                        <template #template>
                            <div class="u-pv-xs">
                                <el-skeleton-item
                                    style="width: 128px;"
                                    variant="text"
                                />
                                <div
                                    class="u-mt-xs"
                                    style="display: flex; justify-content: space-between;"
                                >
                                    <el-skeleton-item
                                        style="width: 96px;"
                                        variant="text"
                                    />
                                    <el-skeleton-item
                                        style="width: 48px;"
                                        variant="text"
                                    />
                                </div>
                            </div>
                            <el-divider class="u-mv-none" />
                        </template>

                        <template #default>
                            <ui-chat-list-item
                                v-for="item in lastMessages"
                                :key="item.id"
                                :active="item.id === activeGroup"
                                :date="item.summary?.lastMessageDate"
                                :subtitle="item.summary?.lastMessageText"
                                :title="item.group.name"
                                :unread-quantity="item.summary?.unreadCount"
                                @click="handleClick(item)"
                            />
                        </template>
                    </el-skeleton>
                </div>
            </div>
            <el-divider
                class="u-h-auto u-m-none"
                direction="vertical"
            />
            <div class="site-notification-dialog__main">
                <site-notification-chat-window
                    v-if="activeGroup"
                    :group="activeGroup"
                />
            </div>
        </div>
    </ui-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import DialogMixin from '@/mixins/dialog';
import SiteNotificationChatWindow from '@/components/profile/SiteNotificationChatWindow.vue';
import { Group, GroupSummary } from '@/types/Notification';
import { Business } from '@/types/Business';
import { NotificationGroups } from '@/enums';

interface LastMessage {
    id: NotificationGroups,
    group: Group,
    summary: GroupSummary | null
}

export default Vue.extend({
    components: { SiteNotificationChatWindow },
    mixins: [DialogMixin],
    data() {
        return {
            searchQuery: '',
            selectedBusinesses: [],
            loading: false,
            pending: false
        };
    },

    computed: {
        businesses(): Business[] {
            return this.$store.getters['business/list'];
        },

        lastMessages(): LastMessage[] {
            return this.$store.getters['notification/lastMessages'];
        },

        groups() {
            return this.$store.getters['notification/lastMessages'];
        },

        activeGroupTitle() {
            return this.$store.state.notification.groups.find(i => i.id === this.activeGroup)?.name ?? '';
        },

        activeGroup: {
            get() {
                return this.$store.state.notification.currentGroup;
            },

            set(val) {
                this.$store.dispatch('notification/setActiveGroup', val);
            }
        }
    },

    watch: {
        currentVisible: {
            async handler(val) {
                if (val) {

                } else {
                    this.searchQuery = '';
                    this.activeGroup = '';
                }
            }
        }
    },

    async created() {
        this.loading = true;

        if (this.businesses.length === 0) {
            this.fetchBusinesses();
        }

        await Promise.all([
            this.fetchLastNotifications(),
            this.fetchNotificationGroups()
        ]);

        this.loading = false;
    },

    methods: {
        fetchBusinesses() {
            this.$store.dispatch('business/fetchList', {
                params: {
                    pagination: 0,
                    businessGroup: this.$store.getters.currentBusinessGroupId
                }
            });
        },

        fetchNotificationGroups() {
            return this.$store.dispatch('notification/fetchGroups');
        },

        fetchLastNotifications() {
            return this.$store.dispatch('notification/fetchSummary');
        },

        handleClick(item: LastMessage) {
            if (item.id !== this.activeGroup) {
                this.activeGroup = item.id;
            }
        },

        async handleReadAllClick() {
            this.pending = true;
            await this.$store.dispatch('notification/markAllAsRead');
            this.pending = false;
        }
    }
});
</script>

<style lang="scss">
.site-notification-dialog {
    .el-dialog {
        &__header {
            padding: 0;
        }

        &__body {
            padding: 0 !important;
        }

        &__headerbtn {
            top: 15px;
        }
    }

    &__wrapper,
    &__header-wrapper {
        display: flex;
    }

    &__wrapper {
        height: 600px;
    }

    &__side {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 300px;
    }

    &__main {
        flex-grow: 1;
    }

    &__chat-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 46px;
    }

    &__header,
    &__chat-title {
        flex-shrink: 0;
        height: 46px;
    }

    &__header {
        display: flex;
        align-items: center;
        padding-right: 10px;
    }

    &__header-buttons {
        margin-left: auto;
        padding-left: 10px;

        .el-button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            padding: 0;
            color: $--color-text-secondary;

            &.is-loading .iconify {
                display: none;
            }
        }
    }

    &__chat-title {
        padding: 0 16px;
    }
}
</style>
