<template>
    <div
        class="timeline-item"
        :class="itemClass"
        :style="isContractOrAmendment ? { marginLeft: 'auto' } : {}"
    >
        <div class="timeline-item__body">
            <div class="timeline-item__header">
                <div class="timeline-item__text">
                    <span class="timeline-item__author">{{ authorName }}</span>
                    <template v-if="isDealTransition || isInvoiceTransition">
                        {{ itemText }}

                        <ui-status
                            v-if="item.extra.newStage"
                            :color="item.extra.newStage?.color || '#fff'"
                            size="mini"
                        >
                            {{ item.extra.newStage.name }}
                        </ui-status>
                        <ui-status
                            v-else-if="item.extra.newStatus"
                            color="#999"
                            size="mini"
                        >
                            {{ $t(`enum.invoiceStageTypes.${item.extra.newStatus}`) }}
                        </ui-status>
                    </template>
                    <template v-else-if="isFundingClaimPaymentTransition">
                        {{ itemText }}

                        <ui-status
                            v-if="getFundingClaimTransitionStatus(item)"
                            :color="getFundingClaimTransitionStatus(item).color || '#fff'"
                            size="mini"
                        >
                            {{ getFundingClaimTransitionStatus(item).name }}
                        </ui-status>
                        <div
                            v-if="item.extra.comment"
                            class="u-mt-xs"
                        >
                            {{ item.extra.comment }}
                        </div>
                    </template>
                    <template v-else-if="isContractTransition">
                        {{ itemText }}

                        <ui-status
                            v-if="getContractTransitionStatus(item)"
                            :color="getContractTransitionStatus(item)?.color || '#fff'"
                            size="mini"
                        >
                            {{ getContractTransitionStatus(item)?.name }}
                        </ui-status>
                        <div
                            v-if="item.extra.comment"
                            class="u-mt-xs"
                        >
                            {{ item.extra.comment }}
                        </div>
                    </template>
                    <template v-else-if="isContractApproveByParticipant || isFundingClaimPaymentApproveByApprover">
                        {{ itemText }}

                        <el-popover
                            v-if="item.extra.versionNumber"
                            ref="popover"
                            placement="top"
                            trigger="click"
                        >
                            <template #reference>
                                <el-button
                                    type="text"
                                    style="font-size: 12px; padding: 0"
                                >
                                    версию документа № {{ item.extra?.versionNumber }}
                                </el-button>
                            </template>
                            <contract-version-row-table
                                in-timeline
                                :data="{
                                    files: item.extra?.versionFiles,
                                    comment: item.extra?.versionComment,
                                }"
                            />
                        </el-popover>
                        <ui-status
                            v-else-if="item.extra.stageName"
                            :color="item.extra.stageColor || '#fff'"
                            size="mini"
                        >
                            {{ item.extra.stageName }}
                        </ui-status>
                    </template>
                    <template v-else-if="isModification">
                        <span v-html="` ${itemText}`" />
                        <el-popover
                            v-if="isMultipleExtra || !isInEnabledSingleFields"
                            :key="`details_${item.id}`"
                            placement="top"
                            trigger="click"
                            :width="dynamicWidth"
                        >
                            <template #reference>
                                <iconify-icon
                                    icon="akar-icons:info-fill"
                                    class="timeline-item__info-icon"
                                />
                            </template>

                            <template v-for="(change, index) in changeSet">
                                <ui-timeline-item-changeset-items
                                    v-if="isItemsEntity && ['payments.items', 'items', 'transactionItems'].includes(change.field)"
                                    :key="index"
                                    :entity-type="item.entityType"
                                    :changeset="change"
                                    :fields-meta="fieldsMeta"
                                />
                                <ui-timeline-item-changeset-commodity-stocks
                                    v-else-if="!isItemsEntity && ['commodityStocks'].includes(change.field)"
                                    :key="index"
                                    :entity-type="item.entityType"
                                    :changeset="change"
                                    :fields-meta="fieldsMeta"
                                />
                                <ui-timeline-item-changeset-fixed-assets
                                    v-else-if="!isItemsEntity && ['fixedAssets'].includes(change.field)"
                                    :key="index"
                                    :entity-type="item.entityType"
                                    :changeset="change"
                                    :fields-meta="fieldsMeta"
                                />
                                <ui-timeline-item-changeset-products
                                    v-else-if="change.field === 'products'"
                                    :key="index"
                                    :entity-type="item.entityType"
                                    :changeset="change"
                                    :fields-meta="fieldsMeta"
                                />
                                <ui-timeline-item-changeset
                                    v-else
                                    :key="index"
                                    :entity-type="item.entityType"
                                    :changeset="change"
                                    :fields-meta="fieldsMeta"
                                />
                            </template>
                        </el-popover>
                        <el-popover
                            v-if="isContractVersion"
                            ref="popover"
                            placement="top"
                            trigger="click"
                        >
                            <template #reference>
                                <el-button
                                    type="text"
                                    style="font-size: 12px; padding: 0"
                                >
                                    версию документа № {{ item.extra[0].changes[0].value?.number }}
                                </el-button>
                            </template>
                            <contract-version-row-table
                                in-timeline
                                :data="item.extra[0].changes[0]?.value"
                            />
                        </el-popover>
                    </template>
                    <span
                        v-else-if="isBinding || isUnbinding"
                        v-html="` ${itemText}`"
                    />
                    <template v-else-if="isContractDeadline">
                        {{ itemText }}
                        <span v-if="item.extra.deadlineReason">
                            по причине: {{ item.extra.deadlineReason }}
                        </span>
                    </template>
                    <template v-else-if="!isComment && !isReason">
                        {{ itemText }}
                    </template>
                </div>
            </div>
            <div
                v-if="isComment || isReason"
                class="timeline-item__message"
                v-html="getAnchoredText(item.message)"
            />
            <div
                v-if="isComment && item.files && item.files.length"
                class="timeline-item__files"
            >
                <ui-timeline-file
                    v-for="file in item.files"
                    :key="file.id"
                    :file="file"
                />
            </div>
            <div class="timeline-item__users">
                <el-tag
                    v-for="user in item.mentions"
                    :key="user.id"
                    size="mini"
                >
                    {{ user.fullName }}
                </el-tag>
            </div>
        </div>
        <div
            :title="formatDate(item.createdAt)"
            class="timeline-item__date"
        >
            {{ formatDateShort(item.createdAt) }}
        </div>
        <div class="timeline-item__icons">
            <span
                v-if="commentRelationTitle"
                :title="commentRelationTitle"
                class="u-color-secondary"
            >
                <iconify-icon
                    :icon="item.commentRelation === 'parent' ? 'fluent:people-16-filled' : 'fluent:person-16-filled'"
                />
            </span>
        </div>
    </div>
</template>

<script>
import { camelCase, isEqual, uniqWith } from 'lodash';
import FormatMixin from '@/mixins/format';
import { ContractStageCodes, EntityTypes, FundingClaimPaymentCodeTypes, Genders, TimelineEntryTypes } from '@/config';
import anchorme from 'anchorme';
import ContractVersionRowTable from '@/components/document-flow/ContractVersionRowTable.vue';
import classNames from 'classnames';

export default {
    name: 'UiTimelineItem',
    components: { ContractVersionRowTable },

    mixins: [FormatMixin],

    inject: ['uiTimeline', 'entityTimeline'],

    props: {
        item: {
            type: Object,
            required: true
        }
    },

    computed: {
        isContractOrAmendment () {
            return [EntityTypes.CONTRACT, EntityTypes.AMENDMENT].includes(this.item.entityType);
        },

        ContractStageCodes () {
            return ContractStageCodes;
        },
        isContractVersion () {
            return (
                this.item?.extra?.length > 0 &&
                this.item.extra[0]?.changes?.length > 0 &&
                this.item.extra[0].changes[0]?.field === 'versions'
            );
        },

        dynamicWidth () {
            if (this.hasItemChanges && this.isItemsEntity) {
                return 720;
            }

            if (!this.isItemsEntity && this.isItemsEntityReceiptDocument) {
                return 720;
            }

            return 480;
        },

        itemClass () {
            return classNames({
                'u-ml-auto': this.isAuthorCurrentUser && this.isComment,
                [`timeline-item--${this.item.type.replaceAll('_', '-')}`]: true
            });
        },

        itemIcon () {
            const {
                type,
                extra
            } = this.item;

            if (type === TimelineEntryTypes.CREATION) {
                return 'fa6-solid:plus';
            } else if ([TimelineEntryTypes.MODIFICATION, TimelineEntryTypes.CONTRACT_DEADLINE].includes(type)) {
                return 'fa6-solid:pencil';
            } else if (type === TimelineEntryTypes.COMMENT) {
                return 'fa6-solid:comment';
            } else if ([TimelineEntryTypes.FUNDING_CLAIM_PAYMENT_STAGE_CHANGED, TimelineEntryTypes.CONTRACT_STAGE_CHANGED].includes(type)) {
                return 'fa6-solid:comment-medical';
            } else if ([TimelineEntryTypes.FUNDING_CLAIM_PAYMENT_TRANSITION, TimelineEntryTypes.CONTRACT_TRANSITION].includes(type)) {
                const direction = this.getStageDirection({ extra });

                if (
                    type === TimelineEntryTypes.CONTRACT_TRANSITION &&
                    extra?.oldStage?.code !== ContractStageCodes.DRAFT &&
                    !extra?.oldStage?.code &&
                    direction === 'forward'
                ) {
                    return 'fa6-solid:check';
                }

                return `fa6-solid:${direction}`;
            } else if (type === TimelineEntryTypes.FUNDING_CLAIM_PAYMENT_TRANSACTION_BINDING) {
                return 'fa6-solid:link';
            } else if (type === TimelineEntryTypes.FUNDING_CLAIM_PAYMENT_TRANSACTION_UNBINDING) {
                return 'fa6-solid:link-slash';
            } else if (type === TimelineEntryTypes.CONTRACT_APPROVE_BY_PARTICIPANT) {
                return 'fa6-solid:check';
            }
            return '';
        },

        authorName () {
            if (this.item.originalUser) {
                return `${this.getUserFullName(this.item.originalUser)} (${this.getUserFullName(this.item.user)})`;
            }

            return this.getUserFullName(this.item.user);
        },

        isAuthorCurrentUser () {
            return [this.item.originalUser?.id, this.item.user?.id].includes(this.$store.getters.me.id);
        },

        isAuthorFemale () {
            return this.item?.user?.gender === Genders.FEMALE;
        },

        itemText () {
            const {
                type,
                entityType,
                extra
            } = this.item;
            let key = 'timeline';
            const change = extra?.[0]?.changes ?? extra;

            if (this.isModification && extra && !this.isMultipleExtra && this.isInEnabledSingleFields) {
                key += `.${camelCase(entityType)}.${change[0].field}`;
            } else {
                key += `.${camelCase(type)}`;

                if (this.isFundingClaimPaymentApproveByApprover) {
                    key += '.forward';
                } else if (this.isFundingClaimPaymentTransition) {
                    key += '.' + this.getFundingClaimTransitionKey(this.item);
                } else if (this.isContractTransition || (this.isContractApproveByParticipant && !extra.versionNumber)) {
                    key += '.' + this.getContractTransitionKey(this.item);
                } else if (this.isInvoiceTransition || this.isDealTransition) {
                    key += '.' + this.getStageDirection(this.item);
                } else if (this.isBinding || this.isUnbinding) {
                    const resolvedRoute = this.$router.resolve({
                        name: 'MoneyTransactionsDetail',
                        params: {
                            businessSlug: extra.transaction?.business?.slug ?? this.item?.business?.slug,
                            transactionId: extra.transaction?.id ?? extra.transactionId
                        }
                    });

                    return this.$t(key, { ending: this.isAuthorFemale ? 'а' : '', link: resolvedRoute.href });
                } else {
                    key += '.' + camelCase(entityType);
                }
            }

            const config = {
                ending: this.isAuthorFemale ? 'а' : '',
                serialNumber: '',
                from: '',
                to: '',
                name: ''
            };

            if (this.item?.serialNumber) {
                config.serialNumber = ` №${this.item?.serialNumber}`;
            }

            if (this.isModification && extra && !this.isMultipleExtra && this.isInEnabledSingleFields) {
                if (['payments.paymentDate', 'paymentDate', 'date'].includes(change?.[0]?.field)) {
                    config.from = this.formatDate(change[0].from, 'DD.MM.YYYY');
                    config.to = this.formatDate(change[0].to, 'DD.MM.YYYY');
                }
            }
            if (this.isContractDeadline && extra) {
                config.from = this.formatDate(change.newDeadline, 'DD.MM.YYYY');
                config.to = this.formatDate(change.oldDeadline, 'DD.MM.YYYY');
                config.ending = this.isAuthorFemale ? 'ла' : '';
            }

            if (
                Array.isArray(extra) &&
                [EntityTypes.TRANSACTION, EntityTypes.FUNDING_CLAIM_PAYMENT, EntityTypes.FUNDING_CLAIM, EntityTypes.DEAL, EntityTypes.INVOICE].includes(entityType)
            ) {
                if (extra?.every(i => i.blockId === 'requisites')) {
                    key += '/requisites';
                } else if (extra?.every(i => i.blockId === 'items')) {
                    key += '/items';
                } else if (extra?.every(i => i.blockId === 'approves')) {
                    key += '/approval';
                }
            }

            if ((entityType === EntityTypes.CONTRACT || entityType === EntityTypes.AMENDMENT) && change?.[0]?.field === 'versions') {
                config.name = change[0]?.value?.document?.originalName;
                key += change[0]?.value?.generatedFrom ? '/generate' : '/add';
            }

            return this.$t(key, config);
        },

        isUnbinding () {
            return [
                TimelineEntryTypes.INVOICE_TRANSITION_UNBINDING,
                TimelineEntryTypes.ANALYTIC_TO_DEAL_TRANSACTION_UNBINDING,
                TimelineEntryTypes.FUNDING_CLAIM_PAYMENT_TRANSACTION_UNBINDING,
                TimelineEntryTypes.DEAL_TRANSACTION_REFUND_UNBINDING,
                TimelineEntryTypes.INVOICE_TRANSACTION_REFUND_UNBINDING
            ].includes(this.item.type);
        },

        isBinding () {
            return [
                TimelineEntryTypes.INVOICE_TRANSITION_BINDING,
                TimelineEntryTypes.ANALYTIC_TO_DEAL_TRANSACTION_BINDING,
                TimelineEntryTypes.FUNDING_CLAIM_PAYMENT_TRANSACTION_BINDING,
                TimelineEntryTypes.DEAL_TRANSACTION_REFUND_BINDING,
                TimelineEntryTypes.INVOICE_TRANSACTION_REFUND_BINDING
            ].includes(this.item.type);
        },

        isModification () {
            return this.item.type === TimelineEntryTypes.MODIFICATION;
        },

        isComment () {
            return this.item.type === TimelineEntryTypes.COMMENT;
        },

        isReason () {
            return [TimelineEntryTypes.FUNDING_CLAIM_PAYMENT_STAGE_CHANGED, TimelineEntryTypes.CONTRACT_STAGE_CHANGED].includes(this.item.type);
        },

        isFundingClaimPaymentTransition () {
            return this.item.type === TimelineEntryTypes.FUNDING_CLAIM_PAYMENT_TRANSITION;
        },

        isFundingClaimPaymentApproveByApprover () {
            return this.item.type === TimelineEntryTypes.FUNDING_CLAIM_PAYMENT_APPROVE_BY_APPROVER;
        },

        isInvoiceTransition () {
            return this.item.type === TimelineEntryTypes.INVOICE_TRANSITION;
        },

        isDealTransition () {
            return this.item.type === TimelineEntryTypes.DEAL_TRANSITION;
        },

        isContractTransition () {
            return this.item.type === TimelineEntryTypes.CONTRACT_TRANSITION || this.item.type === TimelineEntryTypes.AMENDMENT_TRANSITION;
        },

        isContractDeadline () {
            return this.item.type === TimelineEntryTypes.CONTRACT_DEADLINE;
        },

        isContractApproveByParticipant () {
            return this.item.type === TimelineEntryTypes.CONTRACT_APPROVE_BY_PARTICIPANT || this.item.type === TimelineEntryTypes.AMENDMENT_APPROVE_BY_PARTICIPANT;
        },

        isMultipleExtra () {
            return this.item.extra.length > 1 || this.item.extra?.[0]?.changes?.length > 1;
        },

        isItemsEntity () {
            return [EntityTypes.TRANSACTION, EntityTypes.FUNDING_CLAIM, EntityTypes.FUNDING_CLAIM_PAYMENT, EntityTypes.DEAL, EntityTypes.INVOICE].includes(this.item.entityType);
        },

        isItemsEntityReceiptDocument () {
            return [EntityTypes.RECEIPT_FIXED_ASSET_DOCUMENT].includes(this.item.entityType);
        },

        hasItemChanges () {
            return this.changeSet.some(change => ['payments.items', 'items', 'transactionItems', 'products'].includes(change.field));
        },

        isInEnabledSingleFields () {
            const config = {
                [EntityTypes.FUNDING_CLAIM_PAYMENT]: ['paymentDate'],
                [EntityTypes.TRANSACTION]: ['date'],
                [EntityTypes.FUNDING_CLAIM]: ['payments.paymentDate'],
                [EntityTypes.CONTRACT]: ['versions'],
                [EntityTypes.AMENDMENT]: ['versions']
            };

            return this.item?.extra?.some(i =>
                config?.[this.item.entityType]?.includes(i.field) ||
                (
                    i?.changes?.length === 1 &&
                    i.changes.some(change => config?.[this.item.entityType]?.includes(change.field))
                )
            );
        },

        changeSet () {
            if (!this.isModification) {
                return [];
            }

            // Иногда нет changes (возможно в старых записях только)
            return uniqWith(this.item.extra.flatMap(item => (item.changes ?? []).map(i => ({
                field: ['customFieldValues'].includes(item.blockId) ? `custom_${i.field}` : i.field,
                trueField: i.field,
                collectionId: i.collectionId
            }))), isEqual)
                .filter(i => typeof this.fieldsMeta[this.item.entityType]?.[i.field] !== 'undefined')
                .map(i => {
                    return {
                        field: i.field,
                        changes: this.item.extra
                            .flatMap(i => i.changes)
                            .filter(item => {
                                let isValid = item.field === i.trueField;

                                if (i.collectionId && item.collectionId !== i.collectionId) {
                                    isValid = false;
                                }

                                return isValid;
                            })
                    };
                });
        },

        fieldsMeta () {
            return this.uiTimeline?.fieldsMeta ?? {};
        },

        commentRelationTitle () {
            const { entityId, entityType, commentRelation } = this.item;

            if (commentRelation && entityId !== this.entityTimeline?.entityId) {
                if (entityType === EntityTypes.CONTRACT) {
                    if (commentRelation === 'child') {
                        return 'Комментарий из дочернего договора';
                    } else if (commentRelation === 'parent') {
                        return 'Комментарий из родительского договора';
                    }
                } else if (entityType === EntityTypes.AMENDMENT) {
                    if (commentRelation === 'child') {
                        return 'Комментарий из дочернего доп.соглашения';
                    } else if (commentRelation === 'parent') {
                        return 'Комментарий из родительского доп.соглашения';
                    }
                }
            }

            return '';
        }
    },

    methods: {
        getFundingClaimTransitionStatus ({ extra, user }) {
            const { oldStage, newStage } = extra;
            const transitionKey = this.getFundingClaimTransitionKey({ extra, user });

            if (
                oldStage?.code === FundingClaimPaymentCodeTypes.NEW &&
                newStage.code !== FundingClaimPaymentCodeTypes.REJECTED &&
                transitionKey.startsWith('forward')
            ) {
                return null;
            }

            return transitionKey === 'forward' ? oldStage : newStage;
        },

        getFundingClaimTransitionKey ({ extra, user }) {
            const newStageCode = extra.newStage?.code;
            const oldStageCode = extra.oldStage?.code;
            const direction = this.getStageDirection({ extra });
            const isForward = direction === 'forward';

            const targetStages = [
                FundingClaimPaymentCodeTypes.PAID,
                FundingClaimPaymentCodeTypes.PARTIALLY_PAID,
                FundingClaimPaymentCodeTypes.UNLOADED_TO_BANK,
                FundingClaimPaymentCodeTypes.REJECTED
            ];

            if (
                oldStageCode === FundingClaimPaymentCodeTypes.NEW &&
                newStageCode !== FundingClaimPaymentCodeTypes.REJECTED &&
                isForward
            ) {
                return 'forwardFirst';
            }

            if (newStageCode === FundingClaimPaymentCodeTypes.NEW && !isForward) {
                return 'backwardSystem';
            }

            if (!user || targetStages.includes(newStageCode)) {
                return `${direction}System`;
            }

            if (!isForward) {
                return 'backwardSystem';
            }

            return 'forward';
        },

        getContractTransitionStatus ({ extra, user }) {
            const { oldStage, newStage } = extra;
            const transitionKey = this.getContractTransitionKey({ extra, user });

            if (
                oldStage?.code === ContractStageCodes.DRAFT &&
                newStage.code !== ContractStageCodes.DECLINED &&
                transitionKey.startsWith('forward')
            ) {
                return null;
            }

            return transitionKey === 'forward' ? oldStage : newStage;
        },

        getContractTransitionKey ({ extra, user }) {
            const newStageCode = extra.newStage?.code;
            const oldStageCode = extra.oldStage?.code;
            const direction = this.getStageDirection({ extra });
            const isForward = direction === 'forward';

            const targetStages = [
                ContractStageCodes.CONTENT_APPROVAL,
                ContractStageCodes.FORMATION,
                ContractStageCodes.WAITING_ORIGINAL,
                ContractStageCodes.ARCHIVE_REGISTRATION
            ];

            if (oldStageCode === ContractStageCodes.DRAFT && newStageCode !== ContractStageCodes.DECLINED && isForward) {
                return 'forwardFirst';
            }

            if (newStageCode === ContractStageCodes.DRAFT && !isForward) {
                return 'backwardSystem';
            }

            if (!user || targetStages.includes(oldStageCode)) {
                return `${direction}System`;
            }

            if (!isForward && !(extra.stageName && extra.stageColor)) {
                return 'backwardSystem';
            }

            if (isForward && [ContractStageCodes.COMPLETED, ContractStageCodes.DECLINED].includes(newStageCode)) {
                return 'forwardSystem';
            }

            return 'forward';
        },

        getStageDirection ({ extra }) {
            return Number(extra?.newStage?.sort) < Number(extra?.oldStage?.sort) ? 'backward' : 'forward';
        },

        getAnchoredText (text) {
            return anchorme({
                input: text,
                options: {
                    attributes: { target: '_blank' }
                }
            });
        },

        formatDateShort (date) {
            const $date = this.$dayjs(date);

            return $date
                .format('H:mm');
        },

        getUserFullName (user) {
            return user ? user.fullName : 'Системный пользователь';
        }
    }
};
</script>

<style lang="scss">
.timeline-item {
    $b: &;

    display: flex;
    max-width: 640px;
    margin: 4px 0;
    box-shadow: $--box-shadow-base;
    border-radius: $--border-radius-base;
    padding: 4px 12px;
    flex-wrap: wrap;
    position: relative;

    &__divider {
        margin-right: 8px;
        padding: 4px 0;
    }

    &__dot {
        font-size: 12px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        color: $--color-text-secondary;
        border-radius: 50%;
        background-color: $--border-color-base;
    }

    &__body {
        flex-grow: 1;
    }

    &__header {
        line-height: 18px;
        display: flex;
        min-height: 18px;
        margin-top: 2px;
    }

    &__text {
        font-size: 12px;
        color: $--color-text-gray;
        word-break: break-word;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__date {
        font-size: 12px;
        width: 48px;
        margin-left: auto;
        padding-left: 8px;
        text-align: right;
        white-space: nowrap;
        color: $--color-text-gray;
        align-self: flex-end;
    }

    &__icons {
        position: absolute;
        top: 4px;
        right: 12px;
    }

    &__author {
        font-weight: bold;
    }

    &__info-icon {
        cursor: pointer;
        transition: $--color-transition-base;
        border-radius: 50%;
        margin-left: 5px;

        &:hover {
            color: $--color-text-regular;
        }
    }

    &__message {
        font-size: 12px;
        word-break: break-word;
    }

    &__files {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;

        .timeline-file {
            padding: 0 12px;
        }

        .timeline-file + .timeline-file {
            margin-top: 4px;
        }
    }

    &:not(&--comment) {
        max-width: 100%;
        background-color: $--border-color-base;
        //margin-left: auto;
        margin-right: auto;
    }

    &--comment {
        max-width: var(--timeline-item-max-width, 400px);
        align-self: flex-start;
        border-radius: $--border-radius-base;
        background-color: $--color-white;
        box-shadow: $--box-shadow-base;
        padding-bottom: 8px;
        display: flex;
        flex-wrap: nowrap;

        #{$b} {
            &__author {
                color: $--color-text-regular;
            }

            &__message {
                white-space: pre-wrap;
            }
        }
    }

    &--funding-claim_payment_stage_changed, &--contract-stage_changed {
        border-radius: $--border-radius-base;
        background-color: $--border-color-base;
        box-shadow: $--box-shadow-base;
        padding-bottom: 8px;

        #{$b} {
            &__author {
                color: $--color-text-regular;
            }
        }
    }

    &__users {
        .el-tag {
            margin-right: 8px;
        }
    }
}
</style>
