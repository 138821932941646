/* eslint comma-dangle: ["error", "always-multiline"] */
/* eslint sort-keys: "error" */
export default {
    amendment: {
        'versions/add': 'добавил{ending} ',
        'versions/generate': 'сгенерировал{ending} ',
    },
    amendmentApproveByParticipant: {
        amendment: 'согласовал{ending}',
        forward: 'согласовал{ending} этап',
    },
    amendmentDeadline: {
        contract: 'перенес{ending} срок согласования с {to} на {from}',
    },
    amendmentTransition: {
        backward: 'вернул{ending} доп.соглашение с этапа',
        backwardFirst: 'вернул{ending} доп. соглашение на согласование',
        backwardSystem: 'вернул{ending} доп. соглашение в статус',
        forward: 'согласовал{ending} этап',
        forwardEnd: 'перевел{ending} доп. соглашение в статус',
        forwardFirst: 'отправил{ending} доп. соглашение на согласование',
        forwardSystem: 'перевел{ending} доп. соглашение на этап',
    },
    analyticToDealTransactionBinding: 'привязал{ending} <a href="{link}" data-drawer="true">операцию</a> к сделке',
    analyticToDealTransactionUnbinding: 'отвязал{ending} <a href="{link}" data-drawer="true">операцию</a> от сделки',
    contract: {
        'versions/add': 'добавил{ending} ',
        'versions/generate': 'сгенерировал{ending} ',
    },
    contractApproveByParticipant: {
        contract: 'согласовал{ending}',
        forward: 'согласовал{ending} этап',
    },
    contractDeadline: {
        contract: 'перенес{ending} срок согласования с {to} на {from}',
    },
    contractTransition: {
        backwardFirst: 'вернул{ending} договор на согласование',
        backwardSystem: 'вернул{ending} договор на этап',
        forward: 'согласовал{ending} этап',
        forwardFirst: 'отправил{ending} договор на согласование',
        forwardSystem: 'перевел{ending} договор на этап',
    },
    creation: {
        amendment: 'создал{ending} доп. соглашение',
        contract: 'создал{ending} договор',
        deal: 'создал{ending} сделку',
        fixedAsset: 'создал{ending} основное средство',
        fundingClaimPayment: 'создал{ending} заявку{serialNumber}',
        internalDocument: 'создал{ending} внутренний договор',
        invoice: 'создал{ending} счёт',
        productInventory: 'создал{ending} товар',
        receiptFixedAssetDocument: 'создал{ending} закупку имущества',
        staffAssessmentDocument: 'создал{ending} начисление зарплаты',
        staffPersonnelDocument: 'создал{ending} кадровый документ',
        transaction: 'создал{ending} операцию',
    },
    dealTransactionRefundBinding: 'привязал{ending} <a href="{link}" data-drawer="true">операцию возврата</a> по сделке',
    dealTransactionRefundUnbinding: 'отвязал{ending} <a href="{link}" data-drawer="true">операцию возврата</a> от сделки',
    dealTransition: {
        backward: 'вернул{ending} сделку в статус',
        forward: 'перевел{ending} сделку в статус',
    },
    fundingClaim: {
        payments: {
            paymentDate: 'изменил{ending} Дату оплаты{serialNumber} с <span class="u-color-primary">{from}</span> на <span class="u-color-primary">{to}</span>',
        },
    },
    fundingClaimPayment: {
        paymentDate: 'изменил{ending} Дату оплаты{serialNumber} с <span class="u-color-primary">{from}</span> на <span class="u-color-primary">{to}</span>',
    },
    fundingClaimPaymentApproveByApprover: {
        forward: 'согласовал{ending} этап заявки{serialNumber}',
    },
    fundingClaimPaymentTransactionBinding: 'привязал{ending} <a href="{link}" data-drawer="true">операцию</a> к заявке{serialNumber}',
    fundingClaimPaymentTransactionUnbinding: 'отвязал{ending} <a href="{link}" data-drawer="true">операцию</a> от заявки{serialNumber}',
    fundingClaimPaymentTransition: {
        backwardFirst: 'вернул{ending} заявку{serialNumber} на согласование',
        backwardSystem: 'вернул{ending} заявку{serialNumber} на этап',
        forward: 'согласовал{ending} этап заявки{serialNumber}',
        forwardFirst: 'отправил{ending} заявку{serialNumber} на согласование',
        forwardSystem: 'перевел{ending} заявку{serialNumber} на этап',
    },
    invoiceTransactionBinding: 'привязал{ending} <a href="{link}" data-drawer="true">операцию</a> к счету',
    invoiceTransactionRefundBinding: 'привязал{ending} <a href="{link}" data-drawer="true">операцию возврата</a> по счету',
    invoiceTransactionRefundUnbinding: 'отвязал{ending} <a href="{link}" data-drawer="true">операцию возврата</a> от счета',
    invoiceTransactionUnbinding: 'отвязал{ending} <a href="{link}" data-drawer="true">операцию</a> от счета',
    invoiceTransition: {
        backward: 'вернул{ending} счёт в статус',
        forward: 'перевел{ending} счёт в статус',
    },
    modification: {
        amendment: 'изменил{ending} доп. соглашение',
        contract: 'изменил{ending} договор',
        deal: 'изменил{ending} сделку',
        'deal/requisites': 'изменил{ending} реквизиты сделки',
        fixedAsset: 'изменил{ending} основное средство',
        fundingClaim: 'изменил{ending} заявку',
        'fundingClaim/approval': 'изменил{ending} данные для согласования',
        'fundingClaim/items': 'изменил{ending} расшифровку заявки{serialNumber}',
        'fundingClaim/requisites': 'изменил{ending} реквизиты заявки{serialNumber}',
        fundingClaimPayment: 'изменил{ending} заявку{serialNumber}',
        'fundingClaimPayment/approval': 'изменил{ending} данные для согласования заявки{serialNumber}',
        'fundingClaimPayment/items': 'изменил{ending} расшифровку заявки{serialNumber}',
        'fundingClaimPayment/requisites': 'изменил{ending} реквизиты заявки{serialNumber}',
        internalDocument: 'изменил{ending} внутренний договор',
        invoice: 'изменил{ending} счёт',
        'invoice/requisites': 'изменил{ending} реквизиты счёта',
        productInventory: 'изменил{ending} товар',
        receiptFixedAssetDocument: 'изменил{ending} закупку имущества',
        'receiptFixedAssetDocument/commodityStocks': 'изменил{ending} расшифровку заявки{serialNumber}',
        staffAssessmentDocument: 'изменил{ending} начисление зарплаты',
        staffPersonnelDocument: 'изменил{ending} кадровый документ',
        transaction: 'изменил{ending} операцию',
        'transaction/items': 'изменил{ending} расшифровку операции',
        'transaction/requisites': 'изменил{ending} реквизиты операции',
    },
    transaction: {
        date: 'изменил{ending} Дату операции с <span class="u-color-primary">{from}</span> на <span class="u-color-primary">{to}</span>',
    },
};
