<template>
    <el-tooltip
        :placement="placement"
        popper-class="help-tooltip"
    >
        <div
            class="help-icon"
            :class="iconColorClass"
        >
            <iconify-icon
                :icon="icon"
                class="help-icon__icon"
            />
        </div>

        <template #content>
            <slot />
        </template>
    </el-tooltip>
</template>

<script>
export default {
    name: 'UiHelp',

    props: {
        type: {
            type: String,
            default: 'question',
            validator: v => ['question', 'exclamation', 'exclamation-light'].includes(v),
        },
        placement: {
            type: String,
            default: 'right',
        },
        color: {
            type: String,
            default: 'auto',
            validator: v => ['auto', 'warning', 'danger', 'secondary'].includes(v),
        }
    },

    computed: {
        icon () {
            if (this.type === 'question') {
                return 'fa6-solid:circle-question';
            }

            if (this.type === 'exclamation') {
                return 'fa6-solid:circle-exclamation';
            }

            if (this.type === 'exclamation-light') {
                return 'fa6-solid:exclamation';
            }

            return '';
        },
        iconColorClass () {
            if (this.color !== 'auto') {
                return `u-color-${this.color}`;
            }

            if (this.type.startsWith('exclamation')) {
                return 'u-color-warning';
            }

            return 'u-color-secondary';
        }
    },
};
</script>

<style lang="scss">
.help-tooltip {
    &.is-dark a {
        color: $--color-primary-light-7;
    }
}
</style>
