<template>
    <portal to="sticky-panel">
        <slot />
    </portal>
</template>

<script>
export default {
    name: 'UiStickyPanel',

    data () {
        return {
            el: null,
            observer: null,
        };
    },

    mounted () {
        this.$nextTick(() => {
            this.el = document.querySelector('.sticky-panel');
            if (this.el) {
                this.observer = new IntersectionObserver(
                    ([e]) => e.target.classList.toggle('is-sticky', e.intersectionRatio < 1),
                    {
                        rootMargin: '0px 0px -1px 0px',
                        threshold: [1],
                    },
                );
                this.observer.observe(this.el);
            }
        });
    },

    beforeDestroy () {
        if (this.observer) {
            this.observer.unobserve(this.el);
        }
    },
};
</script>

<style lang="scss">
.sticky-panel {
    position: sticky;
    bottom: 0;
    margin: 0 -30px;
    padding: 20px 30px;
    min-width: 1169px;

    &:empty {
        display: none;
    }

    &.is-sticky {
        z-index: 10;
        background: $--body-background-color;
        box-shadow: 0 -2px 2px -1px rgba(152, 162, 179, .3), 0 -1px 5px -2px rgba(152, 162, 179, .3);
    }

    @media (max-width: 1200px) {
        min-width: 0;
    }
}
</style>
