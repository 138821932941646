import type { AxiosRequestConfig } from 'axios';
import type { BusinessGroup, Member, Role } from '@/types/BusinessGroup';
import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { pickBy } from 'lodash';

const baseUri = '/business_groups';

export const getBusinessGroups = (params: any): Promise<PagedCollection<BusinessGroup>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    } as AxiosRequestConfig);

export const getBusinessGroup = (id: string): Promise<BusinessGroup> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    } as AxiosRequestConfig);

export const createBusinessGroup = (data: any): Promise<BusinessGroup> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    } as AxiosRequestConfig);

export const deleteBusinessGroup = (id: string): Promise<null> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    } as AxiosRequestConfig);

export const updateBusinessGroup = (id: string, data: any): Promise<BusinessGroup> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    } as AxiosRequestConfig);

export interface GetPermissionsRequest {
    businessGroupId: string;
}

export interface GetPermissionsResponse {
    permissions: string[];
}

export const getPermissions = ({ businessGroupId }: GetPermissionsRequest): Promise<GetPermissionsResponse> =>
    request({
        url: `${baseUri}/${businessGroupId}/permissions`,
        method: 'GET',
    } as AxiosRequestConfig);

export interface GetMembersRequest {
    businessGroupId: string;
    search?: string;
    businesses?: string[];
    page?: number;
    pageSize?: number;
}

export const getMembers = ({ businessGroupId, search, businesses, page, pageSize }: GetMembersRequest): Promise<PagedCollection<Member>> =>
    request({
        url: `${baseUri}/${businessGroupId}/members`,
        method: 'GET',
        params: {
            search,
            businesses,
            page,
            pageSize,
        },
    } as AxiosRequestConfig);

export interface InviteMemberRequest {
    businessGroupId: string;
    email: string;
    permissions?: string[];
    memberships: { business: string, roles: string[] }[];
}

export const inviteMember = ({ businessGroupId, email, permissions, memberships }: InviteMemberRequest): Promise<null> =>
    request({
        url: `${baseUri}/${businessGroupId}/members`,
        method: 'POST',
        data: pickBy({
            email,
            permissions,
            memberships,
        }),
    } as AxiosRequestConfig);

export interface UpdateMemberPermissionsRequest {
    businessGroupId: string;
    userId: string;
    permissions: string[];
}

export const updateMemberPermissions = ({ businessGroupId, userId, permissions }: UpdateMemberPermissionsRequest): Promise<null> =>
    request({
        url: `${baseUri}/${businessGroupId}/members/${userId}/permissions`,
        method: 'PUT',
        data: { permissions },
    } as AxiosRequestConfig);

export interface ResendMemberInvitationRequest {
    businessGroupId: string;
    userId: string;
}

export const resendMemberInvitation = ({ businessGroupId, userId }: ResendMemberInvitationRequest): Promise<null> =>
    request({
        url: `${baseUri}/${businessGroupId}/members/${userId}/resend_invitation`,
        method: 'POST',
    } as AxiosRequestConfig);

export interface AcceptMemberInvitationRequest {
    businessGroupId: string;
    userId: string;
    invitationCode: string;
}

export const acceptMemberInvitation = ({ businessGroupId, userId, invitationCode }: AcceptMemberInvitationRequest): Promise<null> =>
    request({
        url: `${baseUri}/${businessGroupId}/members/${userId}/accept_invitation`,
        method: 'POST',
        data: { invitationCode },
    } as AxiosRequestConfig);

export interface DeleteMemberRequest {
    businessGroupId: string;
    userId: string;
}

export const deleteMember = ({ businessGroupId, userId }: DeleteMemberRequest): Promise<null> =>
    request({
        url: `${baseUri}/${businessGroupId}/members/${userId}`,
        method: 'DELETE',
    } as AxiosRequestConfig);

export interface GetRolesRequest {
    businessGroupId: string;
    search?: string;
    page?: number;
    pageSize?: number;
}

export const getRoles = ({ businessGroupId, search, page, pageSize }: GetRolesRequest): Promise<PagedCollection<Role>> =>
    request({
        url: `${baseUri}/${businessGroupId}/roles`,
        method: 'GET',
        params: {
            search,
            page,
            pageSize,
        },
    } as AxiosRequestConfig);

export interface BlockMemberRequest {
    businessGroupId: string;
    userId: string;
}

export const blockMember = ({ businessGroupId, userId }: BlockMemberRequest): Promise<null> =>
    request({
        url: `${baseUri}/${businessGroupId}/members/${userId}/block`,
        method: 'POST',
    } as AxiosRequestConfig);

export interface UnblockMemberRequest {
    businessGroupId: string;
    userId: string;
}

export const unblockMember = ({ businessGroupId, userId }: UnblockMemberRequest): Promise<null> =>
    request({
        url: `${baseUri}/${businessGroupId}/members/${userId}/unblock`,
        method: 'POST',
    } as AxiosRequestConfig);
