<template>
    <div class="timeline">
        <div
            class="timeline__items u-scrollable u-scrollable--y"
            @scroll="handleItemsScroll"
        >
            <slot />
        </div>

        <slot name="footer" />
    </div>
</template>

<script>
import { camelCase, keyBy, mapValues } from 'lodash';
import { AnalyticTypes, EntityTypes, FieldTypes, PurchaseDocumentTypes, TransactionItemDocumentTypes } from '@/enums';
import FormatMixin from '@/mixins/format';

export default {
    name: 'UiTimeline',

    mixins: [FormatMixin],

    provide () {
        return {
            uiTimeline: this,
            elForm: {}
        };
    },

    props: {
        loadMoreSpace: {
            type: Number,
            default: 48
        },

        loadMoreDelay: {
            type: Number,
            default: 200
        }
    },

    data () {
        return {
            loadMoreTimeout: null,
            lastScrollTop: Number.MAX_SAFE_INTEGER
        };
    },

    computed: {
        fieldsMeta () {
            const analyticsMetadata = {};
            for (const type of Object.values(AnalyticTypes)) {
                const key = `items.analytic${camelCase(type).replace(/^./, type[0].toUpperCase())}`;
                analyticsMetadata[key] = {
                    title: `Расшифровка (${this.$t(`enum.analyticTypes.${type}`).toLowerCase()})`,
                    value: v => v?.name ?? v?.fullName ?? v?.owner ?? null
                };
            }

            const fcpMeta = {
                number: {
                    title: 'Номер'
                },
                date: {
                    title: 'Дата',
                    value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                },
                internal: {
                    title: 'Внутренняя заявка на оплату',
                    value: v => v ? this.$t('message.yes') : this.$t('message.no')
                },
                paymentDate: {
                    title: 'Плановая дата оплаты',
                    value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                },
                files: {
                    title: 'Документы по заявке',
                    value: v => v?.originalName,
                    addActionTitle: 'Добавлен файл',
                    deleteActionTitle: 'Удален файл'
                },
                'bills.date': {
                    title: 'Дата счёта',
                    value: v => this.formatDate(v, 'DD.MM.YYYY'),
                    addActionTitle: 'Добавлен файл',
                    deleteActionTitle: 'Удален файл'
                },
                'bills.number': {
                    title: 'Номер счёта',
                    value: v => v,
                    addActionTitle: 'Добавлен файл',
                    deleteActionTitle: 'Удален файл'
                },
                bills: {
                    title: 'Оплата по счетам (справочно)',
                    value: v => ` ${v?.number ?? ''} от ${this.formatDate(v?.date, 'DD.MM.YYYY')} `,
                    addActionTitle: 'Добавлен счёт',
                    deleteActionTitle: 'Удален счёт'
                },
                payerRequisite: {
                    title: 'Реквизит списания',
                    value: v => v?.info.shortName ?? v?.name
                },
                payerAccount: {
                    title: 'Счет списания',
                    value: v => v?.name
                },
                payeeRequisite: {
                    title: 'Реквизит получателя',
                    value: v => v?.name ?? v?.info?.shortName
                },
                payeeAccount: {
                    title: 'Счет получателя',
                    value: v => v?.name
                },
                purpose: {
                    title: 'Назначение платежа'
                },
                vatType: {
                    title: 'Налог',
                    value: v => this.$t(`enum.vatTypes.${v}`)
                },
                uin: {
                    title: 'УИН'
                },
                thirdPersonRequisite: {
                    title: 'Реквизит третьего лица',
                    value: v => v?.info?.shortName ?? v?.name
                },
                thirdPersonAccount: {
                    title: 'Счёт списания третьего лица',
                    value: v => v?.name
                },
                items: {
                    title: 'Расшифровка',
                    value: v => v ? (`${v.description ?? ''} (${v.category?.name}), ${this.formatPrice(v.amount)}`) : null,
                    addActionTitle: 'Добавлена расшифровка',
                    deleteActionTitle: 'Удалена расшифровка',
                    editActionTitle: 'Изменена расшифровка'
                },
                'items.description': {
                    title: 'Расшифровка (краткое описание)'
                },
                'items.category': {
                    title: 'Расшифровка (статья учета)',
                    value: v => v?.name
                },
                'items.amount': {
                    title: 'Расшифровка (сумма затрат)',
                    value: v => (v && this.formatPrice(v)) || null
                },
                contract: {
                    title: 'Договор',
                    value: v => v ? `№${v.number || 'б/н'}` : null
                },
                ...analyticsMetadata
            };

            const contractCustomFieldsMeta = mapValues(keyBy(this.$store.getters['contractCustomField/list'], item => `custom_${item.code}`), item => ({
                title: item.name,
                value: v => {
                    if (v === null) {
                        return null;
                    }

                    if (item.fieldType === FieldTypes.DATE) {
                        return this.formatDate(v, 'DD.MM.YYYY');
                    }

                    if (item.fieldType === FieldTypes.NUMBER) {
                        return this.formatNumber(v, undefined, true);
                    }

                    if (item.fieldType === FieldTypes.LIST && item.multiple) {
                        return v.join(', ');
                    }

                    if (item.fieldType === FieldTypes.BUSINESS_ACCOUNT || item.fieldType === FieldTypes.BUSINESS_REQUISITE) {
                        return v.name;
                    }

                    return v;
                }
            }));

            return {
                [EntityTypes.FUNDING_CLAIM]: {
                    applicant: {
                        title: 'Заявитель',
                        value: v => (v && v.fullName) || null
                    },
                    description: {
                        title: 'Данные для согласования заявки'
                    },
                    mode: {
                        title: 'Тип заявки',
                        value: v => this.$t(`enum.fundingClaimTypes.${v}`)
                    },
                    number: {
                        title: 'Номер'
                    },
                    date: {
                        title: 'Дата заявки',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    ...Object.fromEntries(
                        Object.entries(fcpMeta).map(([key, value]) => ['payments.' + key, value])
                    )
                },
                [EntityTypes.FUNDING_CLAIM_PAYMENT]: fcpMeta,
                [EntityTypes.TRANSACTION]: {
                    date: {
                        title: 'Дата',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    description: {
                        title: 'Назначение платежа'
                    },
                    number: {
                        title: 'Номер'
                    },
                    receiverAccount: {
                        title: 'Счет/Касса получателя',
                        value: v => v?.name
                    },
                    receiverRequisite: {
                        title: 'Реквизит получателя',
                        value: v => v?.info?.shortName ?? v?.name
                    },
                    senderAccount: {
                        title: 'Счет/Касса плательщика',
                        value: v => v?.name
                    },
                    senderRequisite: {
                        title: 'Реквизит плательщика',
                        value: v => v?.info?.shortName ?? v?.name
                    },
                    thirdPersonAccount: {
                        title: 'Третье лицо (счет/касса)',
                        value: v => v?.name
                    },
                    thirdPersonContractor: {
                        title: 'Третье лицо (контрагент)',
                        value: v => v?.name
                    },
                    thirdPersonRequisite: {
                        title: 'Третье лицо (реквизит)',
                        value: v => v?.info?.shortName ?? v?.name
                    },
                    value: {
                        title: 'Сумма',
                        value: v => (v && this.formatPrice(v)) || null
                    },
                    transactionItems: {
                        title: 'Расшифровка',
                        value: v => {
                            let value = '';
                            const doc = v.document ?? null;
                            if (doc && v.documentType) {
                                if (v.documentType === TransactionItemDocumentTypes.PAYMENT_ITEM) {
                                    value += `Выплата №${doc.payment.number} от ${this.formatUtcDate(doc.payment.date, 'DD.MM.YYYY')}, `;
                                } else if (v.documentType === TransactionItemDocumentTypes.FUNDING_CLAIM_PAYMENT_ITEM) {
                                    value += `Заявка на оплату №${doc.payment.claim.number} от ${this.formatUtcDate(doc.payment.claim.date, 'DD.MM.YYYY')}, `;
                                } else if (v.documentType === TransactionItemDocumentTypes.INVOICE_PRODUCT) {
                                    value += `Счет на оплату №${doc.invoice.number} от ${this.formatUtcDate(doc.invoice.date, 'DD.MM.YYYY')}, `;
                                } else if (v.documentType === PurchaseDocumentTypes.TRANSACTION_ITEM) {
                                    value += `Операция от ${this.formatUtcDate(doc.transaction.date, 'DD.MM.YYYY')} на сумму ${this.formatPrice(Math.abs(doc.transaction.value))}, `;
                                }
                            }

                            value += `${v.description ?? '-'} (${v.transactionCategory?.name}), ${this.formatPrice(v.value)}`;

                            return value;
                        },
                        addActionTitle: 'Добавлена расшифровка',
                        deleteActionTitle: 'Удалена расшифровка',
                        editActionTitle: 'Изменена расшифровка'
                    },
                    'items.description': {
                        title: 'Расшифровка (описание)'
                    },
                    'items.transactionCategory': {
                        title: 'Расшифровка (статья учета)',
                        value: v => v?.name
                    },
                    'items.value': {
                        title: 'Расшифровка (сумма)',
                        value: v => (v && this.formatPrice(v)) || null
                    },
                    mark: {
                        title: 'Метка',
                        value: v => this.$t(`enum.markTypes.${v}`)
                    },
                    contract: {
                        title: 'Договор',
                        value: v => v ? `№${v.number || 'б/н'}` : null
                    },
                    ...analyticsMetadata
                },
                [EntityTypes.STAFF_PERSONNEL_DOCUMENT]: {
                    approvedAt: {
                        title: 'Дата утверждения',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    effectiveAt: {
                        title: 'Вступит в силу',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    positions: {
                        title: 'Занимаемые должности',
                        value: v => v ? (`${v.position?.name ?? ''} (${v.department?.name}), ${this.formatPrice(v.percent)}%`) : null,
                        deleteActionTitle: 'Удалена должность',
                        addActionTitle: 'Добавлена должность'
                    },
                    compensationDays: {
                        title: 'Компенсация',
                        value: v => this.formatPrice(v) || null
                    },
                    salary: {
                        title: 'Фикс. ежемес.',
                        value: v => this.formatPrice(v) || null
                    },
                    netSalary: {
                        title: 'Сумма на руки',
                        value: v => this.formatPrice(v) || null
                    },
                    grossSalary: {
                        title: 'Сумма с учётом НДФЛ',
                        value: v => this.formatPrice(v) || null
                    },
                    advance: {
                        title: 'Аванс',
                        value: v => this.formatPrice(v) || null
                    },
                    probationValue: {
                        title: 'Испытательный срок',
                        value: v => v
                    },
                    vacationDays: {
                        title: 'Отпускные',
                        value: v => this.formatPrice(v) || null
                    },
                    effectiveTo: {
                        title: 'Действует до',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    returnedDate: {
                        title: 'Дата возврата',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    'items.position': {
                        title: 'Должность',
                        value: v => v.name
                    },
                    'items.percent': {
                        title: 'Процент',
                        value: v => v
                    },
                    'items.department': {
                        title: 'Отдел',
                        value: v => v.name
                    },
                    'probation.value': {
                        title: 'Испытательный срок',
                        value: v => v
                    },
                    'probation.unit': {
                        title: 'Единица измерения испытательного срока',
                        value: v => this.$t(`enum.probationTypes.${v}`)
                    }
                },
                [EntityTypes.STAFF_ASSESSMENT_DOCUMENT]: {
                    approvedAt: {
                        title: 'Дата утверждения',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    items: {
                        title: 'Начисления/удержания',
                        value: v => `${v.name ?? '-'}, ${this.formatPrice(v.amount)}`,
                        addActionTitle: 'Добавлена строка',
                        deleteActionTitle: 'Удалена строка'
                    },
                    'items.name': {
                        title: 'Начисление/удержание (наименование)'
                    },
                    'items.category': {
                        title: 'Начисление/удержание (статья учёта)',
                        value: v => v?.name
                    },
                    'items.amount': {
                        title: 'Начисление/удержание (сумма)',
                        value: v => (v && this.formatPrice(v)) || null
                    },
                    'items.description': {
                        title: 'Начисление/удержание (описание)'
                    }
                },

                [EntityTypes.DEAL]: {
                    name: {
                        title: 'Наименование',
                        value: v => v || null
                    },
                    contractor: {
                        title: 'Контрагент',
                        value: v => v.name || null
                    },
                    value: {
                        title: 'Сумма сделки',
                        value: v => (v && this.formatPrice(v)) || null
                    },
                    actDate: {
                        title: 'Дата акта',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    startDate: {
                        title: 'Срок реализации начало',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    endDate: {
                        title: 'Срок реализации конец',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    products: {
                        title: 'Услуги',
                        value: v => v
                    },
                    responsible: {
                        title: 'Ответственный',
                        value: v => v.fullName || null
                    },
                    project: {
                        title: 'Проект',
                        value: v => v.name || null
                    },
                },

                [EntityTypes.RECEIPT_FIXED_ASSET_DOCUMENT]: {
                    number: {
                        title: 'Номер документа',
                        value: v => v || null
                    },
                    date: {
                        title: 'Дата документа',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    supplier: {
                        title: 'Поставщик',
                        value: v => v?.info.shortName
                    },
                    // не работает
                    deliveryDate: {
                        title: 'Дата поставки',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    // не работает поля на бэке нет, реализовано на фронте
                    purchased: {
                        title: 'Куплено ранее',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    paid: {
                        title: 'Оплачено',
                        value: v => v || null
                    },
                    duty: {
                        title: 'Мы должны',
                        value: v => v || null
                    },
                    commentary: {
                        title: 'Комментарии',
                        value: v => v || null
                    },
                    purchasedPreviously: {
                        title: 'Куплено ранее',
                        value: v => v === true ? 'Да' : 'Нет' || null
                    },
                    commodityStocks: {
                        title: 'Товарные запасы',
                        value: v => v ? v : null,
                        addActionTitle: 'Добавлена расшифровка',
                        deleteActionTitle: 'Удалена расшифровка',
                        editActionTitle: 'Изменена расшифровка'
                    },
                    fixedAssets: {
                        title: 'Основные средства',
                        value: v => v ? v : null,
                        addActionTitle: 'Добавлена расшифровка',
                        deleteActionTitle: 'Удалена расшифровка',
                        editActionTitle: 'Изменена расшифровка'
                    }
                },

                [EntityTypes.INVOICE]: {
                    payerRequisite: {
                        title: 'Контрагент',
                        value: v => v.info.shortName || null
                    },
                    payeeAccount: {
                        title: 'Наш реквизит',
                        value: v => v.name || null
                    },
                    thirdPersonAccount: {
                        title: 'Третье лицо (счет/касса)',
                        value: v => v?.name
                    },
                    thirdPersonContractor: {
                        title: 'Третье лицо (контрагент)',
                        value: v => v?.name
                    },
                    number: {
                        title: 'Номер'
                    },
                    itemsSum: {
                        title: 'Сумма счёта',
                        value: v => (v && this.formatPrice(v)) || null
                    },
                    date: {
                        title: 'Дата',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    planPaymentDate: {
                        title: 'Плановая дата оплаты',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    description: {
                        title: 'Комментарий'
                    },
                    useSchedule: {
                        title: 'Использовать график платежей',
                        value: v => v ? 'Да' : 'Нет'
                    },
                    files: {
                        title: 'Файл, счета, документы',
                        value: v => v?.originalName,
                        addActionTitle: 'Добавлен файл',
                        deleteActionTitle: 'Удален файл'
                    },
                    products: {
                        title: 'Услуги',
                        value: v => v
                    },
                    contract: {
                        title: 'Договор',
                        value: v => v ? `№${v.number || 'б/н'}` : null
                    }
                },
                [EntityTypes.FIXED_ASSET]: {
                    responsible: {
                        title: 'Ответственный',
                        value: v => v.name.fullName || null
                    },
                    location: {
                        title: 'Место хранения',
                        value: v => v.name || null
                    },
                    description: {
                        title: 'Доп. информация',
                        value: v => v || null
                    },
                    'exploitation.commissioningDate': {
                        title: 'Дата ввода в эксплуатацию',
                        value: v => (v && this.formatDate(v, 'DD.MM.YYYY')) || null
                    },
                    termOfUse: {
                        title: 'Срок амортизации',
                        value: v => v || null
                    },
                    minResidualValue: {
                        title: 'Остаточная стоимость',
                        value: v => v || null
                    },
                    lossInFirstMonth: {
                        title: 'Потеря в первый месяц',
                        value: v => v || null
                    },
                    'exploitation.termOfUse': {
                        title: 'Срок эксплуатации',
                        value: v => v || null
                    },
                    'exploitation.residualValue': {
                        title: 'Остаточная стоимость',
                        value: v => v || null
                    },
                    'exploitation.amortizationPeriod': {
                        title: 'Срок эксплуатации',
                        value: v => v || null
                    },
                    name: {
                        title: 'Наименование ОС',
                        value: v => v || null
                    },
                    category: {
                        title: 'Категория',
                        value: v => v.name || null
                    },
                    inventoryNumber: {
                        title: 'Инвентарный номер',
                        value: v => v || null
                    },
                    price: {
                        title: 'Сумма закупки',
                        value: v => v || null
                    },
                    cancellationDocument: {
                        title: 'Списание ОС',
                        value: v => `Списание от ${this.formatUtcDate(v.cancellationDate, 'DD.MM.YYYY')}`
                    }
                },
                [EntityTypes.PRODUCT_INVENTORY]: {
                    category: {
                        title: 'Категория',
                        value: v => v.name || null
                    },
                    name: {
                        title: 'Наименование',
                        value: v => v || null
                    },
                    nomenclatureNumber: {
                        title: 'Номенклатурный номер',
                        value: v => v || null
                    },
                    averagePrice: {
                        title: 'Средняя цена закупки',
                        value: v => v || null
                    },
                    price: {
                        title: 'Стоимость по прайсу',
                        value: v => v || null
                    },
                    description: {
                        title: 'Доп. информация',
                        value: v => v || null
                    },
                    quantity: {
                        title: 'Количество',
                        value: v => v || null
                    },
                    productLocation: {
                        title: 'Склад',
                        value: v => v.name || null
                    }
                },
                [EntityTypes.CONTRACT]: {
                    initiator: {
                        title: 'Инициатор',
                        value: v => v.name?.fullName ?? v.fullName
                    },
                    contractSubject: {
                        title: 'Предмет договора',
                        value: v => v || null
                    },
                    comment: {
                        title: 'Описание/коммент',
                        value: v => v || null
                    },
                    number: {
                        title: 'Номер документа',
                        value: v => v || null
                    },
                    date: {
                        title: 'Дата документа',
                        value: v => v ? this.formatDate(v, 'DD.MM.YYYY') : null
                    },
                    startDate: {
                        title: 'Дата начала',
                        value: v => v ? this.formatDate(v, 'DD.MM.YYYY') : null
                    },
                    endDate: {
                        title: 'Дата окончания',
                        value: v => v ? this.formatDate(v, 'DD.MM.YYYY') : null
                    },
                    originalIncomingDate: {
                        title: 'Дата окончания',
                        value: v => v ? this.formatDate(v, 'DD.MM.YYYY') : null
                    },
                    businessFullNameSignatory: {
                        title: 'ФИО подписанта (наша сторона)',
                        value: v => v || null
                    },
                    businessJobPosition: {
                        title: 'Должность подписанта (наша сторона)',
                        value: v => v || null
                    },
                    businessActsOnTheBasis: {
                        title: 'Действует на основании (наша сторона)',
                        value: v => v || null
                    },
                    contractorFullNameSignatory: {
                        title: 'ФИО подписанта (контрагент)',
                        value: v => v || null
                    },
                    contractorJobPosition: {
                        title: 'Должность подписанта (контрагент)',
                        value: v => v || null
                    },
                    contractorActsOnTheBasis: {
                        title: 'Действует на основании (контрагент)',
                        value: v => v || null
                    },
                    contractType: {
                        title: 'Тип договора',
                        value: v => v?.name || null
                    },
                    contractLocation: {
                        title: 'Местонахождение',
                        value: v => v?.name || null
                    },
                    businessAccount: {
                        title: 'Счёт бизнеса',
                        value: v => v?.name || null
                    },
                    businessRequisite: {
                        title: 'Реквизит бизнеса',
                        value: v => v?.info.shortName || null
                    },
                    contractorAccount: {
                        title: 'Счёт контрагента',
                        value: v => v?.name || null
                    },
                    contractorRequisite: {
                        title: 'Реквизит контрагента',
                        value: v => v?.info.shortName || null
                    },
                    thirdPersonAccount: {
                        title: 'Счёт третьего лица',
                        value: v => v?.name || null
                    },
                    thirdPersonRequisite: {
                        title: 'Реквизит третьего лица',
                        value: v => v?.info.shortName || null
                    },
                    stage: {
                        title: 'Стадия',
                        value: v => v?.name || null
                    },
                    versions: {
                        title: 'Версия документа',
                        addActionTitle: 'Добавлен файл',
                        value: v => v?.document?.originalName || null
                    },

                    // для внутренних доков (таймлайн один для двух сущностей)
                    name: {
                        title: 'Название документа',
                        value: v => v || null
                    },

                    ...contractCustomFieldsMeta
                },
                [EntityTypes.AMENDMENT]: {
                    activationDateType: {
                        title: 'Вступает в силу',
                        value: v => this.$t(`enum.activationDateTypes.${v}`)
                    },
                    activationDateValue: {
                        title: 'Дата вступления в силу',
                        value: v => v ? this.formatDate(v, 'DD.MM.YYYY') : null
                    },
                    shortDescription: {
                        title: 'Краткое описание',
                        value: v => v || null
                    },
                    description: {
                        title: 'Описание',
                        value: v => v || null
                    },
                    number: {
                        title: 'Номер документа',
                        value: v => v || null
                    },
                    date: {
                        title: 'Дата документа',
                        value: v => v ? this.formatDate(v, 'DD.MM.YYYY') : null
                    },
                    startDate: {
                        title: 'Дата начала',
                        value: v => v ? this.formatDate(v, 'DD.MM.YYYY') : null
                    },
                    endDate: {
                        title: 'Дата окончания',
                        value: v => v ? this.formatDate(v, 'DD.MM.YYYY') : null
                    },
                    originalIncomingDate: {
                        title: 'Дата окончания',
                        value: v => v ? this.formatDate(v, 'DD.MM.YYYY') : null
                    },
                    businessFullNameSignatory: {
                        title: 'ФИО подписанта (наша сторона)',
                        value: v => v || null
                    },
                    businessJobPosition: {
                        title: 'Должность подписанта (наша сторона)',
                        value: v => v || null
                    },
                    businessActsOnTheBasis: {
                        title: 'Действует на основании (наша сторона)',
                        value: v => v || null
                    },
                    contractorFullNameSignatory: {
                        title: 'ФИО подписанта (контрагент)',
                        value: v => v || null
                    },
                    contractorJobPosition: {
                        title: 'Должность подписанта (контрагент)',
                        value: v => v || null
                    },
                    contractorActsOnTheBasis: {
                        title: 'Действует на основании (контрагент)',
                        value: v => v || null
                    },
                    contractType: {
                        title: 'Тип договора',
                        value: v => v?.name || null
                    },
                    contractLocation: {
                        title: 'Местонахождение',
                        value: v => v?.name || null
                    },
                    businessAccount: {
                        title: 'Счёт бизнеса',
                        value: v => v?.name || null
                    },
                    businessRequisite: {
                        title: 'Реквизит бизнеса',
                        value: v => v?.info.shortName || null
                    },
                    contractorAccount: {
                        title: 'Счёт контрагента',
                        value: v => v?.name || null
                    },
                    contractorRequisite: {
                        title: 'Реквизит контрагента',
                        value: v => v?.info.shortName || null
                    },
                    thirdPersonAccount: {
                        title: 'Счёт третьего лица',
                        value: v => v?.name || null
                    },
                    thirdPersonRequisite: {
                        title: 'Реквизит третьего лица',
                        value: v => v?.info.shortName || null
                    },
                    stage: {
                        title: 'Стадия',
                        value: v => v?.name || null
                    },
                    versions: {
                        title: 'Версия документа',
                        addActionTitle: 'Добавлен файл',
                        value: v => v?.document?.originalName || null
                    },

                    // для внутренних доков (таймлайн один для двух сущностей)
                    name: {
                        title: 'Название документа',
                        value: v => v || null
                    },

                    ...contractCustomFieldsMeta
                }
            };
        }
    },

    methods: {
        reset () {
            this.lastScrollTop = Number.MAX_SAFE_INTEGER;
        },

        handleItemsScroll ({ target }) {
            if (target.scrollTop > this.lastScrollTop) {
                return;
            }
            this.lastScrollTop = target.scrollTop;
            const scrollDiff = target.scrollHeight - (target.offsetHeight - target.scrollTop);
            if (scrollDiff <= this.loadMoreSpace) {
                if (this.loadMoreTimeout) {
                    clearTimeout(this.loadMoreTimeout);
                }
                this.loadMoreTimeout = setTimeout(() => {
                    this.$emit('load-more');
                }, this.loadMoreDelay);
            }
        }
    }
};
</script>

<style lang="scss">
.timeline {
    position: relative;
    display: flex;
    flex-direction: column;

    &__items {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        flex-grow: 1;
        margin: 2px 0;
        padding: 2px 8px;
    }
}
</style>
