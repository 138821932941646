import { createCrudModule } from '@/utils/vuex-crud';
import { createKpi, deleteKpi, getKpi, getKpis, updateKpi } from '@/api/staff/kpi';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: any) {
        return getKpis(params);
    },
    doFetchSingle (id: string) {
        return getKpi({ id });
    },
    doCreate (data: any) {
        return createKpi(data);
    },
    doUpdate (id: string, data: any) {
        return updateKpi({ id, ...data });
    },
    doDelete (id: string) {
        return deleteKpi({ id });
    },
});
