import request from '@/utils/request';

const baseUri = '/staff/departments';

export default {
    find (id, params) {
        return request.get(`${baseUri}/${id}`, { params });
    },

    findAll (params) {
        return request.get(baseUri, { params });
    },

    create (payload = {}) {
        return request.post(baseUri, payload);
    },

    update (id, payload, params) {
        return request.patch(`${baseUri}/${id}`, payload, { params });
    },

    delete (id) {
        return request.delete(`${baseUri}/${id}`);
    },

    deactivate (id) {
        return request.post(`${baseUri}/${id}/deactivate`);
    },

    changeLeader (id, payload, params) {
        return request.put(`${baseUri}/${id}/head`, payload, { params });
    },

    getAccessRoles (id, params) {
        return request({
            url: `${baseUri}/${id}/access_roles`,
            method: 'GET',
            params,
        });
    },

    getAccessRole (departmentId, id) {
        return request({
            url: `${baseUri}/${departmentId}/access_roles/${id}`,
            method: 'GET',
        });
    },

    sortRoles (departmentId, order) {
        return request({
            url: `${baseUri}/${departmentId}/access_roles/sort`,
            method: 'POST',
            data: {
                order,
            },
        });
    },

    resendInvitation (departmentId, userId) {
        return request({
            url: `${baseUri}/${departmentId}/members/${userId}/resend_invitation`,
            method: 'POST'
        });
    }
};
