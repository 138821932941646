<template>
    <el-dropdown
        v-if="$slots.default"
        trigger="click"
        :disabled="disabled"
        class="actions-menu"
        @command="handleCommand"
    >
        <el-button
            type="text"
            size="mini"
        >
            <iconify-icon icon="akar-icons:more-horizontal" />
        </el-button>
        <template #dropdown>
            <el-dropdown-menu>
                <slot />
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script>
export default {
    name: 'UiActionsMenu',

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        handleCommand (command) {
            this.$emit('action', command);
        },
    },
};
</script>
