import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { ContractTemplate } from '@/types/Contract';

const baseUri = '/contract_templates';

export const getContractTemplates = (params: object): Promise<PagedCollection<ContractTemplate>> =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const getContractTemplate = (id: string): Promise<ContractTemplate> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET'
    });

export const createContractTemplate = (data: Record<string, any>): Promise<ContractTemplate> => {
    return request({
        url: baseUri,
        method: 'POST',
        data: data,
    });
};

export const deleteContractTemplate = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE'
    });

export const updateContractTemplate = (id: string, data: object): Promise<ContractTemplate> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data
    });
