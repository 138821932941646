<template>
    <div
        v-if="props.data"
        class="deal-option"
    >
        <div class="deal-option__title">
            {{ props.data.name }}
        </div>
        <div
            v-if="'contractor' in data"
            class="deal-option__info"
        >
            <div>
                Контрагент: {{ data.contractor?.name }}
            </div>
            <div v-if="'value' in data">
                Сумма: {{ formatPrice(data.value) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiDealOption'
});
</script>

<script setup lang="ts">
import type { Deal, DealBasic, DealShort } from '@/types/Deal';
import { useFormat } from '@/composable/useFormat';

interface Props {
    data?: DealShort | DealBasic | Deal | null;
}

const props = defineProps<Props>();

const { formatPrice } = useFormat();
</script>

<style lang="scss">
.deal-option {
    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__info {
        color: $--color-text-secondary;
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
    }
}
</style>
