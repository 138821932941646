<template>
    <div
        class="timeline-file"
        :class="{ 'has-url': props.file }"
        @click="handleFileClick"
    >
        <div
            v-if="!hideIcon"
            class="timeline-file__icon"
        >
            <iconify-icon icon="fa6-solid:file" />
        </div>
        <div class="timeline-file__info">
            <div class="timeline-file__name">
                {{ props.file?.originalName }}
            </div>
            <div class="timeline-file__size">
                {{ formatFileSize(props.file?.size) }}
            </div>
        </div>
        <div
            v-if="props.deletable"
            class="timeline-file__actions"
        >
            <el-button
                circle
                plain
                size="mini"
                type="danger"
                @click="handleDeleteClick"
            >
                <iconify-icon icon="fa6-solid:trash-can" />
            </el-button>
        </div>

        <ui-doc-viewer
            v-if="viewerVisible && props.file"
            :file="props.file"
            :visible.sync="viewerVisible"
        />
    </div>
</template>

<script lang="ts">
export default {
    name: 'UiTimelineFile'
};
</script>

<script lang="ts" setup>
import { useFormat } from '@/composable/useFormat';
import { MediaObject } from '@/types/MediaObject';
import { ref } from 'vue';

const { formatFileSize } = useFormat();

interface Props {
    file?: MediaObject | null,
    deletable?: boolean,
    hideIcon?: boolean
}

interface Emits {
    (e: 'delete'): void;
}

const props = withDefaults(defineProps<Props>(), {
    deletable: false,
    file: null,
    hideIcon: false
});

const emit = defineEmits<Emits>();

const viewerVisible = ref(false);

const handleFileClick = () => {
    if (props.file) {
        viewerVisible.value = true;
    }
};

const handleDeleteClick = () => {
    emit('delete');
};
</script>

<style lang="scss">
.timeline-file {
    display: flex;
    align-items: center;
    padding: 8px 12px;

    &.has-url {
        cursor: pointer;
    }

    &.has-url:hover {
        text-decoration: underline;
    }

    &__icon {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        color: $--color-text-secondary;
        border-radius: 50%;
        background-color: $--border-color-base;
    }

    &__info {
        flex-grow: 1;
        max-width: 400px;
    }

    &__name {
        font-size: 13px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $--color-text-regular;
    }

    &__size {
        font-size: 12px;
        color: $--color-text-secondary;
    }

    &__actions {
        margin-left: 12px;
    }
}
</style>
