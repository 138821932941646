import type { AxiosRequestConfig } from 'axios';
import { omit } from 'lodash';
import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { Calculation, Kpi } from '@/types/Kpi';
import { StaffKpiCalculationType as CalculationType } from '@/enums';

const baseUri = '/staff/kpi';

export interface GetKpisRequest {
    business: string;
    name?: string;
    calculationType?: CalculationType | CalculationType[];
    id?: string | string[];
    department?: string | string[];
    employee: string | string[];
    order?: {
        ['employee.name.fullName']?: 'asc' | 'desc';
    };
    page?: number;
    pageSize?: number;
}

export const getKpis = (params: GetKpisRequest): Promise<PagedCollection<Kpi>> =>
    request({
        url: `${baseUri}/kpis`,
        method: 'GET',
        params,
    } as AxiosRequestConfig);

export interface GetKpiRequest {
    id: string;
}

export const getKpi = ({ id }: GetKpiRequest): Promise<Kpi> =>
    request({
        url: `${baseUri}/kpis/${id}`,
        method: 'GET',
    } as AxiosRequestConfig);

export interface CreateKpiRequest {
    business: string;
    name: string;
    calculationType: CalculationType;
}

export const createKpi = (data: CreateKpiRequest): Promise<Kpi> =>
    request({
        url: `${baseUri}/kpis`,
        method: 'POST',
        data,
    } as AxiosRequestConfig);

export interface UpdateKpiRequest {
    id: string;
    name?: string;
    calculationType?: CalculationType;
}

export const updateKpi = ({ id, name, calculationType }: UpdateKpiRequest): Promise<Kpi> =>
    request({
        url: `${baseUri}/kpis/${id}`,
        method: 'PATCH',
        data: {
            name,
            calculationType,
        },
    } as AxiosRequestConfig);

export interface DeleteKpiRequest {
    id: string;
}

export const deleteKpi = ({ id }: DeleteKpiRequest): Promise<null> =>
    request({
        url: `${baseUri}/kpis/${id}`,
        method: 'DELETE',
    } as AxiosRequestConfig);

export interface GetCalculationsRequest {
    business?: string;
    employee?: string;
    department?: string;
    month?: string;
}

export const getCalculations = (params: GetCalculationsRequest): Promise<PagedCollection<Calculation>> =>
    request({
        url: `${baseUri}/calculations`,
        method: 'GET',
        params,
    } as AxiosRequestConfig);

export interface GetCalculationRequest {
    calculationId: string;
}

export const getCalculation = ({ calculationId }: GetCalculationRequest): Promise<Calculation> =>
    request({
        url: `${baseUri}/calculations/${calculationId}`,
        method: 'GET',
    } as AxiosRequestConfig);

export interface CreateCalculationRequest {
    employee: string;
    department: string;
    month: string;
}

export const createCalculation = (data: CreateCalculationRequest): Promise<Calculation> =>
    request({
        url: `${baseUri}/calculations`,
        method: 'POST',
        data,
    } as AxiosRequestConfig);

export interface CreateCalculationItemRequest {
    calculationId: string;
    kpi: string;
    name: string;
    attributes: Record<string, any>;
    deal?: string | null;
}

export const createCalculationItem = (data: CreateCalculationItemRequest): Promise<Calculation> =>
    request({
        url: `${baseUri}/calculations/${data.calculationId}/items`,
        method: 'POST',
        data: omit(data, 'calculationId')
    } as AxiosRequestConfig);

export interface UpdateCalculationItemRequest {
    calculationId: string;
    itemId: string;
    name: string;
    attributes: Record<string, any>;
    deal?: string | null;
}

export const updateCalculationItem = (data: UpdateCalculationItemRequest): Promise<Calculation> =>
    request({
        url: `${baseUri}/calculations/${data.calculationId}/items/${data.itemId}`,
        method: 'PATCH',
        data: omit(data, ['calculationId', 'itemId'])
    } as AxiosRequestConfig);

export interface DeleteCalculationItemRequest {
    calculationId: string;
    itemId: string;
}

export const deleteCalculationItem = ({ calculationId, itemId }: DeleteCalculationItemRequest): Promise<null> =>
    request({
        url: `${baseUri}/calculations/${calculationId}/items/${itemId}`,
        method: 'DELETE',
    } as AxiosRequestConfig);
