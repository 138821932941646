import request from '@/utils/request';
import { Item, PagedCollection } from '@/types/hydra';
import { Deal, DealBasic, DealsFinancialControl, DealStage } from '@/types/Deal';
import { ExpensePlanTypes } from '@/enums';
import { Transaction } from '@/types/Transaction';
import { FundingClaim } from '@/types/FundingClaim';

const baseUri = '/deals';

export const getDeals = (params: object): Promise<PagedCollection<DealBasic | Deal>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getDeal = (id: string, params: object): Promise<DealBasic | Deal> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createDeal = (data: object, params: object): Promise<Deal> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const updateDeal = (id: string, data: object, params: object): Promise<Deal> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const deleteDeal = (id: string): Promise<Deal> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const updateDealStage = (id: string, data: object, params: object): Promise<DealStage> =>
    request({
        url: `${baseUri}/${id}/change_stage`,
        method: 'PATCH',
        params,
        data,
    });

export const getExpenses = (id: string, params: object = {}) =>
    request({
        url: `${baseUri}/${id}/expenses`,
        method: 'GET',
        params,
    });

export const getDealsPresets = (params: object = {}) =>
    request({
        url: `${baseUri}/presets`,
        method: 'GET',
        params,
    });

export const getDealsFinancialControl = (id: string): Promise<DealsFinancialControl> =>
    request({
        url: `${baseUri}/${id}/financial_control`,
        method: 'GET',
    });

export const updateDealExpensePlan = (id: string, data: { accountingCategory: string, expenseType: ExpensePlanTypes, value: string }): Promise<void> =>
    request({
        url: `${baseUri}/${id}/expense_plans`,
        method: 'PUT',
        data,
    });

export const getTransactionsFinancialControlDetails = (id: string, params: { id: string, category: string }): Promise<PagedCollection<FinancialControlTransaction>> =>
    request({
        url: `${baseUri}/${id}/financial_control/transactions`,
        method: 'GET',
        params,
    });

interface FinancialControlTransaction extends Item {
    amount: string,
    transaction: Transaction
}

export const getFundingClaimPaymentsFinancialControlDetails = (id: string, params: { id: string, category: string }): Promise<PagedCollection<FinancialControlFundingClaimPayments>> =>
    request({
        url: `${baseUri}/${id}/financial_control/funding_claim_payments`,
        method: 'GET',
        params,
    });

interface FinancialControlFundingClaimPayments extends Item {
    claim: FundingClaim
}
