import type { PluginFunction } from 'vue';
import Vue from 'vue';
import store from '@/store';
import { AccessTypes } from '@/enums';

interface CanFunction {
    (attribute: string, businessId?: string, extended?: boolean): boolean;
}

declare module 'vue/types/vue' {
    interface Vue {
        readonly $can: CanFunction;
    }
}

export default ((app: typeof Vue): void => {
    if (app.prototype.$can) {
        return;
    }

    Object.defineProperties(app.prototype, {
        $can: {
            get (): CanFunction {
                return (attribute: string, businessId?: string, extended = false): boolean => {
                    let isDenied = true;
                    businessId = businessId ?? store.getters['business/currentId'];

                    if (businessId) {
                        if (store.getters['security/hasAccess'](attribute, businessId)) {
                            isDenied = false;
                        }
                    } else {
                        for (const key of Object.keys(store.getters['security/permissions']())) {
                            if (store.getters['security/hasAccess'](attribute, key)) {
                                isDenied = false;
                                break;
                            }
                        }
                    }

                    if (!isDenied && extended) {
                        const codes = store.getters['security/filterCodes'](attribute, businessId).filter(i => i);
                        if (codes && [AccessTypes.BASIC, AccessTypes.EXTENDED].some(type => codes.includes(type))) {
                            if (!codes?.includes(AccessTypes.EXTENDED)) {
                                return false;
                            }
                        }
                    }

                    return !isDenied;
                };
            },
        },
    });
}) as PluginFunction<any>;
