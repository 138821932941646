import type { Module } from 'vuex';
import Vue from 'vue';
import { CanceledError } from 'axios';

interface Params {
    businessDependent?: boolean;
    only?: string[];
    identifier?: string;
    doFetchList?: (params: any) => Promise<any>;
    doFetchSingle?: (id: string) => Promise<any>;
    doCreate?: (payload: any) => Promise<any>;
    doUpdate?: (id: string, payload: any) => Promise<any>;
    doDelete?: (id: string) => Promise<null>;
}

export interface CrudState {
    list: any[];
    entities: Record<string, any>;
    statistics: Record<string, any>;
    total: number;
    meta: {
        businessDependent: boolean;
    },
    isFetchingList?: boolean;
    isFetchingListMore?: boolean;
    fetchListError?: Error | null;
    isFetchedList?: boolean;
    isFetchingSingle?: boolean;
    fetchSingleError?: Error | null;
    isCreating?: boolean;
    createError?: Error | null;
    isUpdating?: boolean;
    updateError?: Error | null;
    isDeleting?: boolean;
    deleteError?: Error | null;
}

export const createCrudModule = ({
    businessDependent = false,
    only = [
        'FETCH_LIST',
        'FETCH_SINGLE',
        'CREATE',
        'UPDATE',
        'DELETE',
        'RESET',
    ],
    identifier = 'id',
    doFetchList = undefined,
    doFetchSingle = undefined,
    doCreate = undefined,
    doUpdate = undefined,
    doDelete = undefined,
}: Params) => {
    const module: Module<CrudState, any> = {
        namespaced: true,
        state: {
            list: [],
            entities: {},
            statistics: {},
            total: 0,
            meta: {
                businessDependent: Boolean(businessDependent),
            },
        },
        getters: {
            list: (state) => state.list.map(id => state.entities[id]),
            byId: (state) => id => state.entities[id],
            isLoading: (state) => state.isFetchingList ||
                state.isFetchingSingle ||
                state.isCreating ||
                state.isUpdating ||
                state.isDeleting,
            isLoadingMore: state => state.isFetchingListMore,
        },
        mutations: {
            SET_ENTITY (state, data) {
                Vue.set(state.entities, data?.[identifier], data);
            },
            UNSET_ENTITY (state, deleteId) {
                // Если не используется постраничка
                if (state.total === 0 || state.list.length <= state.total) {
                    const deleteIndex = state.list.findIndex(id => id === deleteId);
                    if (deleteIndex >= 0) {
                        state.list.splice(deleteIndex, 1);
                    }
                }
                Vue.delete(state.entities, deleteId);
            },
        },
        actions: {},
    };

    if (only && only.includes('FETCH_LIST')) {
        Object.assign(module.state, {
            isFetchingList: false,
            isFetchingListMore: false,
            fetchListError: null,
            isFetchedList: false,
        });

        Object.assign(module.mutations, {
            FETCH_LIST_START (state, { loadMore }) {
                if (!loadMore) {
                    state.isFetchingList = true;
                } else {
                    state.isFetchingListMore = true;
                }
            },
            FETCH_LIST_SUCCESS (state, { data, loadMore }) {
                const items = data['hydra:member'] || [];
                items.forEach(item => {
                    Vue.set(state.entities, item[identifier], item);
                });
                if (!loadMore) {
                    state.list = items.map(item => item[identifier]);
                    state.total = data['hydra:totalItems'] || 0;
                    state.statistics = data['statistics'] ?? {};
                } else {
                    state.list.push(...items.map(item => item[identifier]));
                }
                state.isFetchingList = false;
                state.isFetchingListMore = false;
                state.fetchListError = null;
                state.isFetchedList = true;
            },
            FETCH_LIST_ERROR (state, { error, loadMore }) {
                if (!loadMore) {
                    state.list = [];
                    state.total = 0;
                    state.statistics = {};
                    state.isFetchedList = false;
                }
                state.fetchListError = error;
                state.isFetchingList = false;
                state.isFetchingListMore = false;
            },
        });

        Object.assign(module.actions, {
            async fetchList ({ commit, rootGetters }, {
                params = {},
                filterByCurrentBusiness = true,
                loadMore = false,
            } = {}) {
                if (
                    businessDependent &&
                    filterByCurrentBusiness &&
                    rootGetters['business/currentId'] &&
                    !('business' in params) &&
                    !('businessId' in params)
                ) {
                    params.business = rootGetters['business/currentId'];
                }
                commit('FETCH_LIST_START', { loadMore });
                try {
                    const data = await doFetchList(params);
                    commit('FETCH_LIST_SUCCESS', { data, loadMore });
                    return data;
                } catch (error) {
                    if (!(error instanceof CanceledError)) {
                        console.error(error);
                    }
                    commit('FETCH_LIST_ERROR', { error, loadMore });
                    return null;
                }
            },
        });
    }

    if (only && only.includes('FETCH_SINGLE')) {
        Object.assign(module.state, {
            isFetchingSingle: false,
            fetchSingleError: null,
        });

        Object.assign(module.mutations, {
            FETCH_SINGLE_START (state) {
                state.isFetchingSingle = true;
            },
            FETCH_SINGLE_SUCCESS (state, data) {
                Vue.set(state.entities, data[identifier], data);
                state.isFetchingSingle = false;
                state.fetchSingleError = null;
            },
            FETCH_SINGLE_ERROR (state, error) {
                state.fetchSingleError = error;
                state.isFetchingSingle = false;
            },
        });

        Object.assign(module.actions, {
            async fetchSingle ({ commit, dispatch, rootGetters }, { id, params = {} }) {
                commit('FETCH_SINGLE_START');
                try {
                    const data = await doFetchSingle(id, params);
                    commit('FETCH_SINGLE_SUCCESS', data);

                    if ((data.business || data.businesses) && rootGetters['business/currentId']) {
                        const availableBusinesses = Array.isArray(data.businesses)
                            ? [...data.businesses, data.business]
                            : [data.business];
                        dispatch('setAccessDenied', !availableBusinesses.map(i => i.id).includes(rootGetters['business/currentId']), { root: true });
                    }

                    return data;
                } catch (error) {
                    if (!(error instanceof CanceledError)) {
                        console.error(error);
                        dispatch('setAccessDenied', true, { root: true });
                    }
                    commit('FETCH_SINGLE_ERROR', error);
                    return null;
                }
            },
        });
    }

    if (only && only.includes('CREATE')) {
        Object.assign(module.state, {
            isCreating: false,
            createError: null,
        });

        Object.assign(module.mutations, {
            CREATE_START (state) {
                state.isCreating = true;
            },
            CREATE_SUCCESS (state) {
                state.createError = null;
                state.isCreating = false;
            },
            CREATE_ERROR (state, error) {
                state.createError = error;
                state.isCreating = false;
            },
        });

        Object.assign(module.actions, {
            async create ({ commit }, { data, params = {} }) {
                commit('CREATE_START');
                try {
                    const response = await doCreate(data, params);
                    commit('CREATE_SUCCESS', response);
                    return response;
                } catch (error) {
                    if (!(error instanceof CanceledError)) {
                        console.error(error);
                    }
                    commit('CREATE_ERROR', error);
                    return false;
                }
            },
        });
    }

    if (only && only.includes('UPDATE')) {
        Object.assign(module.state, {
            isUpdating: false,
            updateError: null,
        });

        Object.assign(module.mutations, {
            UPDATE_START (state) {
                state.isUpdating = true;
            },
            UPDATE_SUCCESS (state) {
                state.updateError = null;
                state.isUpdating = false;
            },
            UPDATE_ERROR (state, error) {
                state.updateError = error;
                state.isUpdating = false;
            },
        });

        Object.assign(module.actions, {
            async update ({ commit }, {
                id,
                data,
                params = {},
            }) {
                commit('UPDATE_START');
                try {
                    const response = await doUpdate(id, data, params);
                    commit('UPDATE_SUCCESS', response);
                    return response;
                } catch (error) {
                    if (!(error instanceof CanceledError)) {
                        console.error(error);
                    }
                    commit('UPDATE_ERROR', error);
                    return false;
                }
            },
        });
    }

    if (only && only.includes('DELETE')) {
        Object.assign(module.state, {
            isDeleting: false,
            deleteError: null,
        });

        Object.assign(module.mutations, {
            DELETE_START (state) {
                state.isDeleting = true;
            },
            DELETE_SUCCESS (state) {
                state.deleteError = null;
                state.isDeleting = false;
            },
            DELETE_ERROR (state, error) {
                state.deleteError = error;
                state.isDeleting = false;
            },
        });

        Object.assign(module.actions, {
            async remove ({ commit }, { id }) {
                commit('DELETE_START');
                try {
                    await doDelete(id);
                    commit('DELETE_SUCCESS', id);
                    return true;
                } catch (error) {
                    if (!(error instanceof CanceledError)) {
                        console.error(error);
                    }
                    commit('DELETE_ERROR', error);
                    // throw error;
                    return false;
                }
            },
        });
    }

    if (only && only.includes('RESET')) {
        Object.assign(module.mutations, {
            RESET (state) {
                state.total = 0;
                state.list = [];
                state.entities = {};
                state.statistics = {};
                state.isFetchedList = false;
            },
        });

        Object.assign(module.actions, {
            reset ({ commit }) {
                commit('RESET');
            },
        });
    }

    return module;
};
