<template>
    <portal :to="target">
        <div
            class="drawer-panel"
            :class="{ 'is-static': isTopWindow, 'force-sticky': forceSticky }"
        >
            <div class="drawer-panel__content">
                <slot />
            </div>
        </div>
    </portal>
</template>

<script>
import { getTopWindow } from '@/utils';

export default {
    name: 'UiDrawerPanel',

    props: {
        target: {
            type: String,
            default: 'sticky-panel',
        },

        forceSticky: {
            type: Boolean,
            default: false,
        },
    },

    data () {
        return {
            el: null,
            observer: null,
        };
    },

    computed: {
        isTopWindow () {
            if (this.forceSticky) {
                return false;
            }

            return window.self === getTopWindow();
        },
    },

    mounted () {
        if (this.isTopWindow) {
            return;
        }

        this.$nextTick(() => {
            this.el = document.querySelector('.drawer-panel');
            if (this.el) {
                this.observer = new IntersectionObserver(
                    ([e]) => e.target.classList.toggle('is-sticky', e.intersectionRatio < 1),
                    {
                        rootMargin: '0px 0px -1px 0px',
                        threshold: [1],
                    },
                );
                this.observer.observe(this.el);
            }
        });
    },

    beforeDestroy () {
        if (this.observer) {
            this.observer.unobserve(this.el);
        }
    },
};
</script>

<style lang="scss">
.drawer-panel {
    position: sticky;
    bottom: 0;
    margin: 0 -30px;
    padding: 20px 30px;

    &.is-static {
        position: static;
    }

    &.is-sticky {
        z-index: 10;
        background: $--body-background-color;
        box-shadow: 0 -2px 2px -1px rgba(152, 162, 179, .3), 0 -1px 5px -2px rgba(152, 162, 179, .3);
    }

    &.force-sticky {
        bottom: calc(var(--content-padding-v) * -1);
    }

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

}
</style>
