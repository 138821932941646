<template>
    <div
        class="navigation-drawer"
        :class="{ 'is-collapsed': isCollapsed }"
    >
        <div class="navigation-drawer__prepend">
            <slot name="prepend">
                <div
                    v-if="props.showTitle"
                    class="navigation-drawer__title"
                    v-text="title"
                />
            </slot>
        </div>
        <div class="navigation-drawer__content u-scrollable u-scrollable--y">
            <slot />
        </div>
        <div class="navigation-drawer__border">
            <div
                class="navigation-drawer__resize-button"
                @click="handleResizeButtonClick"
            >
                <el-button
                    circle
                    size="mini"
                >
                    <iconify-icon icon="akar-icons:chevron-left" />
                </el-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useStore } from '@/composable/useStore';
import { useRoute } from 'vue-router/composables';
import { useWindowSize } from '@vueuse/core';

const route = useRoute();
const store = useStore();
const { width: windowWidth } = useWindowSize();

interface Props {
    showTitle?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    showTitle: true
});

const isCollapsed = computed<boolean>(() => store.getters['ui/isNavDrawerCollapsed']);
const autoMode = ref(true);

const handleResizeButtonClick = () => {
    autoMode.value = false;
    store.dispatch('ui/toggleNavDrawer');
};

watch(() => windowWidth.value, (width) => {
    if (!autoMode.value) {
        return;
    }

    if (width >= 1400) {
        store.dispatch('ui/showNavDrawer');
    } else {
        store.dispatch('ui/hideNavDrawer');
    }
});

const title = computed<string>(() => {
    const { matched } = route;
    const matchedRoute = matched[matched.length - 1];
    let parent = matchedRoute.parent;
    if (matchedRoute.meta && matchedRoute.meta.navigationParent) {
        parent = matched.find(route => route.name === matchedRoute.meta.navigationParent);
    }
    return parent && parent.meta && parent.meta.title ? parent.meta.title : '';
});
</script>
