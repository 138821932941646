<template>
    <ui-table-filter-select
        :key="selectKey"
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchItems"
        :init-method="fetchInitItems"
        :multiple="multiple"
        :fetch-on-create="multiple ? currentValue.length > 0 : currentValue"
        :disabled-item-method="disabledItemMethod"
        enable-select-group
        v-on="$listeners"
    >
        <template #option="{ item }">
            <div :style="getOptionStyle(item)">
                {{ item.name }}
            </div>
        </template>
    </ui-table-filter-select>
</template>

<script>
import { pickBy } from 'lodash';
import WrapperMixin from '@/mixins/wrapper';
import { getContractTypes } from '@/api/contractType';

export default {
    name: 'UiTableFilterContractType',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: [String, Array],
            default: '',
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        category: {
            type: [String, Array],
            default: '',
        },
    },

    data () {
        return {
            selectKey: 0,
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        business () {
            return this.$store.getters['business/current'];
        },
    },

    watch: {
        category: {
            handler () {
                this.selectKey++;
                if (this.value.length > 0) {
                    this.$emit('clear');
                }
            },

            immediate: true,
        },
    },

    methods: {
        async fetchItems (query, cb) {
            const params = {
                ...this.fetchParams,
                business: this.business?.id,
                order: { name: 'asc' },
            };

            if (query) {
                params.name = query;
            }
            if (this.category) {
                params.category = this.category;
            }

            try {
                const data = await getContractTypes(pickBy(params));
                const items = data['hydra:member'] || [];
                const groupedItems = this.groupItemsByCategory(items);

                cb(groupedItems);
            } catch (e) {
                this.$flashError(e);
            }
        },

        async fetchInitItems (items) {
            const params = {
                business: this.business?.id,
                id: items.filter(item => !['without_contract_type_income', 'without_contract_type_expense', 'income', 'expense'].includes(item)),
            };

            try {
                const data = await getContractTypes(pickBy(params));
                const items = data['hydra:member'] || [];
                return this.groupItemsByCategory(items);
            } catch (e) {
                this.$flashError(e);
                return [];
            }
        },

        groupItemsByCategory(items) {
            const groupedItems = items.reduce((acc, item) => {
                const category = item.category;

                if (!acc[category]) {
                    acc[category] = {
                        name: category === 'income' ? 'Доходные договоры' : 'Расходные договоры',
                        id: category,
                        category: category,
                        isGroup: true,
                        active: true,
                        children: [],
                    };
                }
                acc[category].children.push(item);
                return acc;
            }, {});

            return [
                ...(groupedItems.income ? [{ ...groupedItems.income }, ...groupedItems.income.children] : []),
                {
                    name: 'Без типа (Доходные)',
                    id: 'without_contract_type_income',
                    active: true,
                },
                ...(groupedItems.expense ? [{ ...groupedItems.expense }, ...groupedItems.expense.children] : []),
                {
                    name: 'Без типа (Расходные)',
                    id: 'without_contract_type_expense',
                    active: true,
                }
            ];
        },

        getOptionStyle (item) {
            const style = {
                paddingLeft: `${item.level * 20}px`,
            };

            if (!item.active) {
                style.color = '#9daab6';
            }

            return style;
        },

        disabledItemMethod (item) {
            return this.value.some(i => i === item.category);
        },
    },
};
</script>
